import React, { useCallback, useState } from 'react';
import { truncateString } from '@utils/string.util';
import DefaultButton from '@components/Atoms/DefaultButton';
import ResponseModal from '@components/ModalContent/CommentResponse';
import ViewCommentModal from '@components/ModalContent/ViewComment';
import { newFormatDate } from '@utils/date.util';
import Card from '@components/Atoms/Card';
import styles from './SpeakUpActionCard.module.scss';

const SpeakUpActionCard: React.FunctionComponent<ActionsCardProps> = (props) => {
  const {
    focusArea,
    focusAreaColor,
    action,
    scores,
    categoryName,
    completed,
    createdDate,
    addToCompleted,
    showResponseButton,
    saveResponseProps,
    responsesEnabled,
    response,
  } = props;
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [openViewCommentsModal, setOpenViewCommentsModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleCloseResponseModal = useCallback(() => {
    setOpenResponseModal(false);
  }, []);

  const handleOpenResponseModal = useCallback(() => {
    setOpenResponseModal(true);
  }, []);

  const handleSubmitComment = useCallback(
    (data: { content: string }, methods: any) => {
      setOpenResponseModal(false);
      saveResponseProps(data.content);
      methods.reset();
    },
    [saveResponseProps],
  );

  const handleOpenViewCommentModal = useCallback(
    (cont: string) => {
      setOpenViewCommentsModal(true);
      setModalContent(cont);
    },
    [action],
  );

  const handleCloseViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(false);
    setModalContent('');
  }, []);

  return (
    <Card className={styles.card}>
      <div className={styles.cardWrapper}>
        <div className={styles.date}>
          <div className={styles.dateWrapper}>{newFormatDate(createdDate)}</div>
        </div>

        <div className={styles.middleContainer}>
          <div
            className={`${styles.leftItem} ${
              !showResponseButton ? styles.btnEnabled : styles.btnNotEnabled
            }`}
          >
            <h5 className={styles.focusArea}>{focusArea}</h5>
            <p className={styles.truncateSection}>
              {truncateString(action, 430)}
              {action.length >= 430 && (
                <a className={styles.viewMore} onClick={() => handleOpenViewCommentModal(action)}>
                  View More
                </a>
              )}
            </p>
          </div>
          <div className={styles.rightItem}>
            <div className={styles.focusContainer}>
              <div className={styles.scoresSection}>
                {scores?.length > 0 &&
                  scores
                    .sort((a, b) => a.score - b.score)
                    .slice(0, 5)
                    .map((data: SocresInterface, index: number) => (
                      <div key={index} className={styles.categoryLine}>
                        <span>{data.score}</span>
                        <p>{data.questionText}</p>
                      </div>
                    ))}
              </div>
              <p className={styles.truncateSection}>
                {truncateString(action, 430)}
                {action.length >= 430 && (
                  <a className={styles.viewMore} onClick={() => handleOpenViewCommentModal(action)}>
                    View More
                  </a>
                )}
              </p>
            </div>
            {!showResponseButton && (
              <div className={styles.inputContainer}>
                <DefaultButton
                  disabled={showResponseButton}
                  variant="primary"
                  onClick={handleOpenResponseModal}
                >
                  Respond
                </DefaultButton>
              </div>
            )}
          </div>

          {!showResponseButton && (
            <>
              <div className={styles.horizonalLine} />
              <div className={styles.inputContainerV2}>
                <DefaultButton
                  disabled={showResponseButton}
                  variant="primary"
                  onClick={handleOpenResponseModal}
                >
                  Respond
                </DefaultButton>
              </div>
            </>
          )}
        </div>
        {responsesEnabled && response && (
          <div className={styles.speakupResponseSection}>
            <div className={styles.horizonalLineV2} />
            <div className={styles.date}>
              <div className={styles.dateWrapper}>{newFormatDate(createdDate)}</div>
            </div>

            <div className={styles.responseSection}>
              <h5
                className={styles.focusArea}
              >{`${response?.responderFirstName} ${response?.responderLastName}`}</h5>
              <p className={styles.truncateSection}>
                {response?.responseMessage && truncateString(response?.responseMessage, 430)}
                {response?.responseMessage?.length >= 430 && (
                  <a
                    className={styles.viewMore}
                    onClick={() => handleOpenViewCommentModal(response?.responseMessage)}
                  >
                    View More
                  </a>
                )}
              </p>
            </div>
          </div>
        )}
      </div>

      <ResponseModal
        title="Respond to user"
        isOpen={openResponseModal}
        onClose={handleCloseResponseModal}
        onSave={handleSubmitComment}
      />

      <ViewCommentModal
        title={focusArea}
        isOpen={openViewCommentsModal}
        onClose={handleCloseViewCommentModal}
      >
        {modalContent && modalContent.split('\n').join('<br/>')}
      </ViewCommentModal>
    </Card>
  );
};

SpeakUpActionCard.defaultProps = {
  showResponseButton: false,
  response: undefined,
};

type ActionsCardProps = {
  focusArea: string;
  focusAreaColor: any;
  action: string;
  scores: SocresInterface[];
  completed: boolean;
  createdDate: string;
  addToCompleted: () => void;
  showResponseButton?: boolean;
  saveResponseProps: (thoughtResponseText: string) => void;
  categoryName: string;
  responsesEnabled: boolean;
  response?: {
    responderFirstName: string;
    responderLastName: string;
    responseCreated: string;
    responseId: number;
    responseMessage: string;
    responseSeen: boolean;
  };
};

interface SocresInterface {
  assessment_id: number;
  instantHelpTriggerId: number;
  questionId: number;
  questionText: string;
  score: number;
}

export default SpeakUpActionCard;
