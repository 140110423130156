import { getCompanyDetailsByUrl } from '@api/auth/company';
import { getCompanyDetails } from '@helpers/auth.helper';
import { PublicCompanyDetails, castResponse } from 'custom.d';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

const Seo: React.FunctionComponent<SeoProps> = (props) => {
  const { title, description, name, type } = props;
  const { companyURL } = useParams<UrlParamsType>();

  const [companyDetails, setCompanyDetails] = useState<PublicCompanyDetails | null>(null);
  let organizationName;

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetails();
      if (data) {
        const compDetails = {
          companyLogo: data.company_logo,
          companyName: data.company_name,
          companyUrl: data.company_url,
          hideHapstarLogo: false,
          id: data.id,
          signUpText: data.sign_up_text,
          loginText: data.login_text,
          shareThoughts: data.share_thoughts,
          platformName: null,
        };
        setCompanyDetails(compDetails);
      }
    };

    const getCompanyDataByURL = async () => {
      const response = await getCompanyDetailsByUrl(companyURL);
      if (response?.success) {
        const data = castResponse<PublicCompanyDetails>(response);
        setCompanyDetails(data);
      } else {
        setCompanyDetails(null);
      }
    };

    if (companyURL) {
      getCompanyDataByURL();
    } else {
      getCompanyData();
    }
  }, [companyURL]);

  if (companyDetails !== null && companyDetails.id !== 0) {
    organizationName = companyDetails.companyName && companyDetails.companyName;
  }

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{`${title} | Hapstar ${organizationName ? `| ${organizationName}` : ''}`}</title>
      <meta name="description" content={description} />
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

Seo.defaultProps = {
  description: '',
  name: '',
  type: 'summary',
};

interface SeoProps {
  title: string;
  description?: string;
  name?: string;
  type?: 'summary' | 'summary_large_image' | 'app' | 'player';
}

type UrlParamsType = {
  companyURL: string;
};

export default Seo;
