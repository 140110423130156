import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeepDiveQuestionnairePlan, FocusAreaQuestionInterface } from 'custom.d';

type StepType = 'NO_ACTIVE_PLANS' | 'HAS_PLANS_NO_FOCUS_AREAS' | 'HAS_BOTH_PLANS_AND_FOCUS_AREAS';
type SubNavType =
  | 'noPlanAndFocusAreas'
  | 'planCreatedWithFocusAreas'
  | 'hasPlanNoFocusAreas'
  | 'hasPlanAndUpdatedFocusAreas'
  | 'hasPlanAndRemovedFocusAreas';

interface CreatedImprovePlanInterface {
  id: number;
  userId: number;
  questionId: number;
  done: boolean;
  completeAt: string | null;
  progress: number;
  createdAt: string;
  updatedAt: string;
}

interface starScoresState {
  deepDiveQuestionWithPlans: DeepDiveQuestionnairePlan[] | [];
  planFocusAreas: any;
  currentStep: StepType | null;
  selectedFocusAreas: FocusAreaQuestionInterface[];
  subNavigationStep: SubNavType | null;
  createdImprovementPlan: CreatedImprovePlanInterface | null;
  advisorSelectedFocusAreas: FocusAreaQuestionInterface[];
  isUserSkippedFocusAreas: boolean;
  advisorEnabledAndFocusAreasEdited: boolean;
}

const initialState: starScoresState = {
  deepDiveQuestionWithPlans: [],
  planFocusAreas: [],
  currentStep: null,
  selectedFocusAreas: [],
  subNavigationStep: null,
  createdImprovementPlan: null,
  advisorSelectedFocusAreas: [],
  isUserSkippedFocusAreas: false,
  advisorEnabledAndFocusAreasEdited: false,
};

const starScoresSlice = createSlice({
  name: 'starScores',
  initialState,
  reducers: {
    addDeepDiveQuestionWithPlan: (state, action: PayloadAction<DeepDiveQuestionnairePlan[]>) => ({
      ...state,
      deepDiveQuestionWithPlans: action.payload,
    }),
    updateCurrentStep: (
      state,
      action: PayloadAction<{
        step: StepType;
        focusAreas: any;
      }>,
    ) => ({
      ...state,
      currentStep: action.payload.step,
      planFocusAreas: action.payload.focusAreas,
    }),
    setFocusAreas: (state, action: PayloadAction<FocusAreaQuestionInterface[]>) => ({
      ...state,
      selectedFocusAreas: action.payload,
    }),
    setSubNavigationStep: (state, action: PayloadAction<SubNavType>) => ({
      ...state,
      subNavigationStep: action.payload,
    }),
    setCreatedImprovePlanDetails: (state, action: PayloadAction<CreatedImprovePlanInterface>) => ({
      ...state,
      createdImprovementPlan: action.payload,
    }),
    setAdvisorSelectedAreas: (state, action: PayloadAction<FocusAreaQuestionInterface[]>) => ({
      ...state,
      advisorSelectedFocusAreas: action.payload,
    }),
    setUserSkippedFocusSelection: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isUserSkippedFocusAreas: action.payload,
    }),
    setAdvisorEnabledWithAreas: (state, action: PayloadAction<boolean>) => ({
      ...state,
      advisorEnabledAndFocusAreasEdited: action.payload,
    }),
    resetStarScores: () => initialState,
  },
});

export const starScoresSliceActions = starScoresSlice.actions;

export default starScoresSlice;
