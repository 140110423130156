import React, { useCallback, useState } from 'react';
import { truncateString } from '@utils/string.util';
import classNames from 'classnames';
import DefaultButton from '@components/Atoms/DefaultButton';
import ResponseModal from '@components/ModalContent/CommentResponse';
import ViewCommentModal from '@components/ModalContent/ViewComment';
import { newFormatDate } from '@utils/date.util';
import styles from './CommentsActionCard.module.scss';

const CommentsActionCard: React.FunctionComponent<ActionsCardProps> = (props) => {
  const {
    focusArea,
    focusAreaColor,
    action,
    score,
    categoryName,
    completed,
    createdDate,
    addToCompleted,
    showResponseButton,
    saveResponseProps,
  } = props;
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [openViewCommentsModal, setOpenViewCommentsModal] = useState(false);
  const [error, setError] = useState(false);
  const [actionsCheckbox, setActionCheckbox] = useState(false);
  const [thoughtResponseText, setThoughtResponseText] = useState<any>('');

  const styleClasses = classNames([
    styles.hsActionsCard,
    focusAreaColor === '#BEAAF3' && styles.calmLilac,
    focusAreaColor === '#019DF2' && styles.skyBlue,
    focusAreaColor === '#FCD300' && styles.sunYellow,
    focusAreaColor === '#8EEA36' && styles.zingyGreen,
    focusAreaColor === '#06BF97' && styles.aquaGreen,
    focusAreaColor === '#FF8A4A' && styles.warmOrange,
  ]);

  const handleCloseResponseModal = useCallback(() => {
    setOpenResponseModal(false);
  }, []);

  const handleOpenResponseModal = useCallback(() => {
    setOpenResponseModal(true);
  }, []);

  const handleSubmitComment = useCallback(
    (data: any, methods: any) => {
      setOpenResponseModal(false);
      saveResponseProps(data.content);
      methods.reset();
    },
    [saveResponseProps],
  );

  const handleOpenViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(true);
  }, [action]);

  const handleCloseViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(false);
  }, []);

  const isModalView = action?.length >= 75;

  return (
    <div className={styleClasses}>
      <div className={styles.tabContent}>
        {showResponseButton && (
          <>
            <div className={styles.hsActionsCardTitle}>
              <div className={styles.dateContainer}>
                <p className={styles.formattedDate}>{newFormatDate(createdDate)}</p>
                <p className={styles.focusArea}>{focusArea}</p>
              </div>

              <p className={styles.categorySection}>
                <p>{categoryName}</p>
                <span>{score}</span>
              </p>
            </div>
            <div className={styles.hsActionsCardTitle}>
              <p className={styles.truncateSection}>
                {truncateString(action, 75)}
                {isModalView && (
                  <a className={styles.viewMore} onClick={handleOpenViewCommentModal}>
                    View More
                  </a>
                )}
              </p>
            </div>
          </>
        )}

        {!showResponseButton && (
          <>
            <div className={styles.hsActionsCardTitle}>
              <div className={styles.dateContainer}>
                <p className={styles.formattedDate}>{newFormatDate(createdDate)}</p>
                <p className={styles.focusArea}>{focusArea}</p>
              </div>

              <p className={styles.categorySection2}>
                <p>{categoryName}</p>
                <span>{score}</span>
              </p>
            </div>

            <div className={styles.hsActionsCardTitle}>
              <p className={styles.truncateSection}>
                {truncateString(action, 75)}
                {isModalView && (
                  <a className={styles.viewMore} onClick={handleOpenViewCommentModal}>
                    View More
                  </a>
                )}
              </p>
            </div>
          </>
        )}
      </div>

      {!showResponseButton && (
        <>
          <div className={styles.lineBetween} />
          <div className={styles.inputContainer}>
            <DefaultButton
              disabled={showResponseButton}
              variant="primary"
              onClick={handleOpenResponseModal}
            >
              Respond
            </DefaultButton>
          </div>
        </>
      )}

      <ResponseModal
        title="Respond to user"
        isOpen={openResponseModal}
        onClose={handleCloseResponseModal}
        onSave={handleSubmitComment}
      />

      <ViewCommentModal
        title={focusArea}
        isOpen={openViewCommentsModal}
        onClose={handleCloseViewCommentModal}
      >
        {action}
      </ViewCommentModal>
    </div>
  );
};

CommentsActionCard.defaultProps = {
  showResponseButton: false,
};

type ActionsCardProps = {
  focusArea: string;
  focusAreaColor: any;
  action: string;
  score: number;
  completed: boolean;
  createdDate: string;
  addToCompleted: () => void;
  showResponseButton?: boolean;
  saveResponseProps: (thoughtResponseText: string) => void;
  categoryName: string;
};

export default CommentsActionCard;
