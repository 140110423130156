import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import LinkText from '@components/Atoms/Link';
import CompleteRegistrationForm from '@components/Organisms/CompleteRegistrationForm';
import { logoutUser } from '@helpers/auth.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import React from 'react';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import styles from './index.module.scss';

const CompleteRegistration: React.FunctionComponent<CompleteRegistrationProps> = (props) => {
  const { organizationLogo, organizationAltText, organizationId, user } = props;
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig.data);

  const { isMobile } = useWindowDimensions();
  const history = useHistory();
  const hideHapstarLogoEnabled = companyConfigData && companyConfigData.hideHapstarLogo;
  const platformName =
    companyConfigData &&
    companyConfigData.platformName &&
    companyConfigData.platformName !== null &&
    companyConfigData.platformName !== 'null'
      ? companyConfigData.platformName
      : 'Hapstar';

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <AuthenticationLayout
      title="SignUp-Complete"
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
      hideHapstarLogoEnabled={hideHapstarLogoEnabled}
    >
      <div className={styles.cardContainer}>
        <Card>
          <div className={styles.registrationContentContainer}>
            <div className={styles.registrationContent}>
              {organizationLogo && isMobile && !hideHapstarLogoEnabled && (
                <div className={styles.organizationLogoHolder}>
                  <Img
                    alt={organizationAltText || 'Organization logo'}
                    src={organizationLogo}
                    className={styles.img}
                  />
                </div>
              )}
              <h1>Complete your sign-up to {platformName}</h1>
              {organizationId !== 0 && (
                <p>
                  Additional data helps your organisation in providing the right wellbeing support.
                </p>
              )}
              <div className={styles.registrationDetails}>
                <CompleteRegistrationForm user={user} />
              </div>
              <div className={styles.backContainer}>
                <LinkText onClick={handleLogout}>Back</LinkText>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </AuthenticationLayout>
  );
};

CompleteRegistration.displayName = 'CompleteRegistration';

CompleteRegistration.defaultProps = {
  organizationLogo: undefined,
  organizationAltText: undefined,
  organizationId: 0,
};

interface CompleteRegistrationProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  organizationLogo?: string | undefined;
  organizationAltText?: string | undefined;
  organizationId?: number;
  user: any;
}

export default CompleteRegistration;
