import { getCompanyDetailsByUrl } from '@api/auth/company';
import Login from '@components/Templates/Login';
import { PublicCompanyDetails, castResponse } from 'custom.d';
import { Parser } from 'html-to-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const LoginPage: React.FunctionComponent = () => {
  const { companyURL } = useParams<UrlParamsType>();
  const [companyDetails, setCompanyDetails] = useState<PublicCompanyDetails | null>(null);
  let content: JSX.Element | string | undefined;
  let organizationLogo: string | undefined;
  let organizationAltText: string | undefined;
  let organizationId = 0;
  let hideHapstarLogo = false;
  let platformName: string | null = null;

  useEffect(() => {
    const getCompanyData = async () => {
      const response = await getCompanyDetailsByUrl(companyURL);
      if (response?.success) {
        const data = castResponse<PublicCompanyDetails>(response);
        setCompanyDetails(data);
      } else {
        setCompanyDetails(null);
      }
    };

    if (companyURL) {
      getCompanyData();
    }
  }, [companyURL]);

  if (companyDetails !== null) {
    content = Parser().parse(companyDetails.loginText);
    organizationLogo = companyDetails.companyLogo
      ? `${process.env.PUBLIC_URL}/images/logos/${companyDetails.companyLogo}`
      : undefined;
    organizationAltText = `${companyDetails.companyName} logo`;
    organizationId = companyDetails.id;
    hideHapstarLogo = companyDetails.hideHapstarLogo;
    platformName =
      companyDetails.platformName &&
      companyDetails.platformName !== 'null' &&
      companyDetails.platformName !== null
        ? companyDetails.platformName
        : null;
  }

  return (
    <Login
      content={content}
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
      organizationId={organizationId}
      companyURL={companyURL}
      hideHapstarLogo={hideHapstarLogo}
      platformName={platformName}
    />
  );
};

type UrlParamsType = {
  companyURL: string;
};

export default LoginPage;
