import { getAllLearningByFilterCriteria } from '@api/learningContent';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import LearningArticleCard from '@components/Organisms/Learning/LearningArticleCard';
import { getCompanyDetails } from '@helpers/auth.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { Grid, Skeleton } from '@mui/material';
import { PublicCompanyDetails } from 'custom';
import React, { useEffect, useState } from 'react';
import { RootState } from 'store';
import iosBack from '../../../../assets/images/ios-back.svg';
import iosNext from '../../../../assets/images/ios-next.svg';
import styles from './index.module.scss';

const NewsSection: React.FunctionComponent<NewsSectionProps> = ({ loading }) => {
  const { isMobile, isDesktop, isTablet } = useWindowDimensions();
  const [state, setState] = useState(0);
  const [recommendedPosts, setRecommendedPosts] = useState<
    { id: number; innerImageUrl: string; title: string }[]
  >([]);
  const [loadingPosts, setLoadingPosts] = useState<boolean>(true);
  const itemsPerPage = isDesktop ? 4 : isTablet ? 1 : 1;
  const visibleItems = recommendedPosts.slice(
    state * itemsPerPage,
    state * itemsPerPage + itemsPerPage,
  );
  const companyConfigData = useAppSelector((rState: RootState) => rState.companyConfig.data);
  const [companyDetails, setCompanyDetails] = useState<PublicCompanyDetails | null>(null);

  useEffect(() => {
    const getPostsAPI = async () => {
      try {
        const response = await getAllLearningByFilterCriteria({
          order: 'DESC',
          orderBy: 'contentCreatedAt',
          page: 1,
          perPage: 60,
          questionIds: [],
          tagIds: [],
          companyContentOnly: true,
        });

        const modifiedData =
          response?.data?.map((dt) => ({
            id: dt.id,
            innerImageUrl: dt.imageUrl,
            title: dt.title,
          })) || [];

        setRecommendedPosts(modifiedData.slice(0, 12));
        setLoadingPosts(false);
      } catch (err) {
        setLoadingPosts(false);
      }
    };

    getPostsAPI();
  }, []);

  useEffect(() => {
    getOrganizationDetails();
  }, []);

  const getOrganizationDetails = async () => {
    const organization = await getCompanyDetails();
    if (organization) {
      const compDetails = {
        companyLogo: organization.company_logo,
        companyName: organization.company_name,
        companyUrl: organization.company_url,
        hideHapstarLogo: false,
        id: organization.id,
        signUpText: organization.sign_up_text,
        loginText: organization.login_text,
        shareThoughts: organization.share_thoughts,
        platformName: null,
      };
      setCompanyDetails(compDetails);
    }
  };

  const handleNext = () => {
    setLoadingPosts(true);
    if ((state + 1) * itemsPerPage < recommendedPosts.length) {
      setState(state + 1);
    }

    setTimeout(() => {
      setLoadingPosts(false);
    }, 250);
  };

  const handlePrevious = () => {
    setLoadingPosts(true);
    if (state > 0) {
      setState(state - 1);
    }

    setTimeout(() => {
      setLoadingPosts(false);
    }, 250);
  };

  return (
    <Card className={styles.newsContainer}>
      <div className={styles.top}>
        <h5>
          {companyDetails?.companyName
            ? `Recommended by ${companyDetails?.companyName}`
            : `Recommended news`}
        </h5>
        {!loadingPosts && (
          <div className={styles.navigationButtons}>
            <>
              <DefaultButton
                className={styles.navigationBtn}
                onClick={handlePrevious}
                disabled={state === 0}
              >
                <Img src={iosBack} alt="Back" className={styles.navigationIcon} />
              </DefaultButton>
              <DefaultButton
                className={styles.navigationBtn}
                onClick={handleNext}
                disabled={(state + 1) * itemsPerPage >= recommendedPosts.length}
              >
                <Img src={iosNext} alt="Next" className={styles.navigationIcon} />
              </DefaultButton>
            </>
          </div>
        )}
      </div>

      {loadingPosts ? (
        <>
          <Grid container spacing={2} className={styles.learningItemsContainer}>
            {Array.from({ length: itemsPerPage }).map((_, index) => (
              <Grid item xs={12} md={3} key={`skeleton-${index}`}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={277}
                  className={styles.learningLoadingItem}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={4} className={styles.learningItemsContainer}>
            {visibleItems.map((item, index: number) => (
              <Grid item xs={12} md={3} key={index}>
                <LearningArticleCard
                  data={item}
                  loading={loadingPosts}
                  sectionType={item.id === -999 ? 'allLearnings' : 'posts'}
                  navigateTo="oldPosts"
                />
              </Grid>
            ))}
          </Grid>

          {visibleItems?.length === 0 && !loadingPosts && <p>No posts</p>}
        </>
      )}
    </Card>
  );
};

NewsSection.defaultProps = {
  loading: false,
};

interface NewsSectionProps {
  loading?: boolean;
}

export default NewsSection;
