import Img from '@components/Atoms/Img';
import LinkText from '@components/Atoms/Link';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import React from 'react';
import { useParams } from 'react-router';
import logo from '../../../assets/images/logo.svg';
import styles from './index.module.scss';

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const { organizationAltText, organizationLogo, hideHapstarLogoEnabled } = props;
  const { companyURL } = useParams<UrlParamsType>();
  const { isMobile } = useWindowDimensions();
  const isMobileApp = useGoNative();

  return (
    <div className={styles.hsHeader}>
      <nav className={styles.hsNav}>
        {hideHapstarLogoEnabled ? (
          <>
            {organizationLogo ? (
              <>
                <div>
                  <Img
                    alt={organizationAltText || 'Organization logo'}
                    src={organizationLogo}
                    className={styles.img}
                  />
                </div>
                {/* {!isMobile && (
                  <div>
                    <LinkText path="/">
                      <Img alt="Hapstar logo" src={logo} className={styles.img} />
                    </LinkText>
                  </div>
                )} */}
              </>
            ) : (
              <>
                <LinkText path="/">
                  <Img alt="Hapstar logo" src={logo} className={styles.img} />
                </LinkText>
              </>
            )}
          </>
        ) : (
          <>
            <div>
              <LinkText path="/">
                <Img alt="Hapstar logo" src={logo} className={styles.img} />
              </LinkText>
            </div>
            {organizationLogo && !isMobile && (
              <div>
                <Img
                  alt={organizationAltText || 'Organization logo'}
                  src={organizationLogo}
                  className={styles.img}
                />
              </div>
            )}
          </>
        )}
      </nav>
    </div>
  );
};

Header.defaultProps = {
  organizationLogo: undefined,
  organizationAltText: undefined,
  hideHapstarLogoEnabled: false,
};

type HeaderProps = {
  organizationAltText?: string;
  organizationLogo?: string | undefined;
  hideHapstarLogoEnabled?: boolean;
};

type UrlParamsType = {
  companyURL: string;
};

export default Header;
