import { getUserImprovePlanActionsById, getUserImprovePlans } from '@api/actionPlan';
import { getAllExperts } from '@api/experts';
import { getAllLearningByFilterCriteria } from '@api/learningContent';
import ActiveIcon from '@assets/images/icons/active-icon.svg';
import DoubleTick from '@assets/images/icons/double-tick.svg';
import BorderedProgressBar from '@components/Atoms/BorderLinearProgressBar';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import DefaultChip from '@components/Atoms/DefaultChip';
import Img from '@components/Atoms/Img';
import TabButton from '@components/Atoms/TabButton';
import ModalContent from '@components/ModalContent';
import CreateImprovePlan from '@components/ModalContent/CreateImprovePlan';
import CompletedPlansSection from '@components/Molecules/ImprovePlans/CompletePlansSection';
import FinishedImprovePlans from '@components/Molecules/ImprovePlans/FinishedImprovePlans';
import ImproveActionCard from '@components/Molecules/ImprovePlans/ImproveActionCard';
import NoPlansSection from '@components/Molecules/ImprovePlans/NoPlansSection';
import ExpertsCarousel from '@components/Organisms/Experts/ExpertsCarousel';
import LearningArticleCard from '@components/Organisms/Learning/LearningArticleCard';
import { useAppSelector } from '@hooks/useAppSelector';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ExpertsResultsResponse } from '@interfaces/index';
import MobileToggler from '@layouts/MainLayout/SecondaryNavigation/components/MobileToggler';
import MainLayout from '@layouts/MainLayout/indexNew';
import { Grid, Skeleton } from '@mui/material';
import { getHourBufferCategory } from '@utils/date.util';
import { ImprovePlanAction, UserImprovePlanActions, castResponse } from 'custom.d';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router';
import { RootState } from 'store';
import styles from './index.module.scss';

interface ColorMap {
  [key: string]: { barColor: string; backgroundColor: string };
}

const colorMap: ColorMap = {
  sunYellow: { barColor: '#FCD300', backgroundColor: '#ffe665' },
  aquaGreen: { barColor: '#06BF97', backgroundColor: '#3be1ce' },
  calmLilac: { barColor: '#BEAAF3', backgroundColor: '#d4c6f9' },
  warmOrange: { barColor: '#FF8A4A', backgroundColor: '#ffaf83' },
  zingyGreen: { barColor: '#8EEA36', backgroundColor: '#bbf484' },
  skyBlue: { barColor: '#019DF2', backgroundColor: '#3fb9fc' },
};

const defaultColors = { barColor: '#f4f2ec', backgroundColor: '#fcfbf7' };

const ImprovePage: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const isMobileApp = useGoNative();
  const [posts, setPosts] = useState<{ id: number; innerImageUrl: string; title: string }[]>([]);
  const [loadingPosts, setLoadingPosts] = useState<boolean>(true);
  const [errorPosts, setErrorPosts] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(1);

  const [userImprovePlans, setUserImprovePlans] = useState<UserImprovePlanActions[]>([]);
  const [userCompletedImprovePlans, setUserCompletedImprovePlans] = useState<
    UserImprovePlanActions[]
  >([]);
  const [userImprovePlanActions, setUserImprovePlanActions] = useState<ImprovePlanAction[]>([]);
  const [selectedImprovePlan, setSelectedImprovePlan] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowDimensions();
  const [filterActiveActions, setFilterActiveActions] = useState(false);
  const [selectedTabDetails, setSelectedTabDetails] = useState<TabDetailsInterface>({
    id: 0,
    label: '',
    labelId: 1,
    actualItemId: 0,
  });
  const [actionModalOpen, setActionModalOpen] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [planProgress, setPlanProgress] = useState<{ planId: number; progress: number }>({
    progress: 0,
    planId: 0,
  });
  const [showCompletedPlans, setShowCompletedPlans] = useState<boolean>(false);
  const [plansLoading, setPlansLoading] = useState<boolean>(true);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    getImprovePlans(true);
  }, [filterActiveActions, showCompletedPlans]);

  const params = new URLSearchParams(location.search);

  const improveAreaId = params.get('improveAreaId');
  const improvePlan = params.get('improvePlan');
  const improveQuestionId = params.get('questionId');
  const viewCompleteActions = queryParams.get('viewCompletedActions');
  const viewCompletePlans = queryParams.get('viewCompletedPlans');

  const [experts, setExperts] = useState<ExpertsResultsResponse>({ results: [], total: 0 });
  const [expertsLoading, setExpertsLoading] = useState(false);

  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);

  useEffect(() => {
    setShowCompletedPlans(viewCompletePlans === 'true');
  }, []);

  useEffect(() => {
    if ((improveAreaId && improveAreaId !== 'null') || (improvePlan && improvePlan !== 'null')) {
      const index = userImprovePlans.findIndex(
        (dt) => dt?.id === Number(improveAreaId) || dt?.question?.questionText === improvePlan,
      );
      if (index !== -1) {
        const newData = (prevData: any) => [
          ...prevData.slice(0, index),
          { ...prevData[index], index },
          ...prevData.slice(index + 1),
        ];

        const updatedData = newData(userImprovePlans).filter((dt) => dt.index)[0];
        if (updatedData) {
          handleTabChange({
            id: updatedData.index,
            label: updatedData.question?.questionText,
            labelId: updatedData.index,
            actualItemId: updatedData.id,
          });
          setSelectedImprovePlan(updatedData);
          if (updatedData?.id) {
            setPlanProgress({ planId: updatedData.id, progress: updatedData?.progress });
          }
        }
      }
    } else if (improveQuestionId && improveQuestionId !== 'null') {
      const index = userImprovePlans.findIndex(
        (dt) => dt?.question?.id === Number(improveQuestionId),
      );
      if (index !== -1) {
        const newData = (prevData: any) => [
          ...prevData.slice(0, index),
          { ...prevData[index], index },
          ...prevData.slice(index + 1),
        ];

        const updatedData = newData(userImprovePlans).filter((dt) => dt.index)[0];
        if (updatedData) {
          handleTabChange({
            id: updatedData.index,
            label: updatedData.question?.questionText,
            labelId: updatedData.index,
            actualItemId: updatedData.id,
          });
          setSelectedImprovePlan(updatedData);
          if (updatedData?.id) {
            setPlanProgress({ planId: updatedData.id, progress: updatedData?.progress });
          }
        }
      }
    }
  }, [improveAreaId, improvePlan, userImprovePlans, improveQuestionId]);

  useEffect(() => {
    if (selectedTabDetails?.actualItemId !== undefined && selectedTabDetails.id !== 9999) {
      const ipId =
        selectedTabDetails?.actualItemId > 0
          ? selectedTabDetails?.actualItemId
          : userImprovePlans[0]?.id;
      const iPlan = userImprovePlans?.filter((dt: UserImprovePlanActions) => dt.id === ipId)[0];
      setSelectedImprovePlan(iPlan);

      if (iPlan?.id) {
        setPlanProgress({ planId: iPlan.id, progress: iPlan?.progress });
      }
    }
  }, [selectedTabDetails, userImprovePlans, improveAreaId, improvePlan]);

  useEffect(() => {
    if (
      userImprovePlans?.length > 0 &&
      selectedTabDetails.actualItemId === 0 &&
      selectedTabDetails.id !== 9999 &&
      !improveAreaId
    ) {
      getImprovePlanActions(userImprovePlans[0]?.id);
    } else if (selectedTabDetails.actualItemId && selectedTabDetails.id !== 9999) {
      getImprovePlanActions(selectedTabDetails.actualItemId);
    } else if (improveAreaId && improveAreaId !== 'null' && Number(improveAreaId) > 0) {
      const iPlanId = userImprovePlans?.filter(
        (dt: UserImprovePlanActions) => dt?.id === Number(improveAreaId),
      )[0]?.id;

      if (iPlanId && iPlanId > 0) {
        getImprovePlanActions(Number(iPlanId));
      }
    } else if (improveQuestionId && improveQuestionId !== 'null' && Number(improveQuestionId) > 0) {
      const iPlanId = userImprovePlans?.filter(
        (dt: UserImprovePlanActions) => dt?.question?.id === Number(improveQuestionId),
      )[0]?.id;

      if (iPlanId && iPlanId > 0) {
        getImprovePlanActions(Number(iPlanId));
      }
    } else {
      setTimeout(() => {
        setLoadingData(false);
      }, 1500);
    }

    setTimeout(() => {
      setLoadingData(false);
    }, 1500);
  }, [userImprovePlans, selectedTabDetails, improveAreaId, improveQuestionId]);

  useEffect(() => {
    queryParams.set('viewCompletedActions', filterActiveActions ? 'true' : 'false');
    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  }, [filterActiveActions]);

  useEffect(() => {
    queryParams.set('viewCompletedPlans', showCompletedPlans ? 'true' : 'false');
    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  }, [showCompletedPlans]);

  const getImprovePlans = async (load = false) => {
    const uPlans = await getUserImprovePlans(true);

    const uPlansCompleted = await getUserImprovePlans(false);
    setUserCompletedImprovePlans(uPlansCompleted?.userImprovePlans);

    const sortedPlans = uPlans?.userImprovePlans.sort((a: any, b: any) => {
      const dateA: Date = new Date(a?.createdAt);
      const dateB: Date = new Date(b?.createdAt);

      return dateA.getTime() - dateB.getTime();
    });

    setUserImprovePlans(sortedPlans);
    setTimeout(() => {
      setPlansLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (companyConfigData.data && companyConfigData.data.expertsEnabled) {
      getExperts();
    }
  }, [userImprovePlans, selectedImprovePlan, companyConfigData.data.expertsEnabled]);

  const getExperts = async () => {
    try {
      setExpertsLoading(true);

      let options;

      if (
        userImprovePlans?.length > 0 &&
        selectedTabDetails.actualItemId === 0 &&
        selectedTabDetails.id !== 9999
      ) {
        options = { id: userImprovePlans[0]?.questionId, page: 0, perPage: 100 };
      } else if (selectedImprovePlan.questionId) {
        options = { id: selectedImprovePlan.questionId, page: 0, perPage: 100 };
      }

      if (options?.id) {
        const response = await getAllExperts(
          null,
          options?.id,
          options?.page,
          options?.perPage,
          null,
        );

        if (response?.success) {
          const data = castResponse<ExpertsResultsResponse>(response);
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const userTimeZoneTime = moment.tz(userTimezone).format('YYYY-MM-DD HH:mm:ss');

          const sortedExperts = (data?.results || [])
            .map((expert) => {
              const zoneName = expert?.expertDetail?.zoneName;
              const expertTimeZoneTime = moment.tz(zoneName).format('YYYY-MM-DD HH:mm:ss');

              const differenceInMinutes = moment(expertTimeZoneTime).diff(
                moment(userTimeZoneTime),
                'minutes',
              );

              return {
                ...expert,
                hourBufferCategory: getHourBufferCategory(differenceInMinutes),
              };
            })
            .sort((a, b) => {
              if (a.hourBufferCategory !== b.hourBufferCategory) {
                return a.hourBufferCategory - b.hourBufferCategory;
              }

              return b.orderId - a.orderId;
            });

          setExperts({ results: sortedExperts, total: data?.total });
        } else {
          setExperts({ results: [], total: 0 });
        }
      }
    } catch {
      setExperts({ results: [], total: 0 });
    } finally {
      setExpertsLoading(false);
    }
  };

  const getImprovePlanActions = async (id: number): Promise<ImprovePlanAction[]> => {
    setLoadingData(true);
    let res: ImprovePlanAction[] = [];
    if (id === 0 && userImprovePlans?.length > 0) {
      const response = await getUserImprovePlanActionsById(userImprovePlans[0]?.id);
      res = response.improvePlanActions;
    } else {
      const response = await getUserImprovePlanActionsById(id);
      res = response.improvePlanActions;
    }

    if (viewCompleteActions === 'true') {
      setUserImprovePlanActions(
        res
          ?.filter(
            (dt) =>
              dt.improvePlanActionDone === filterActiveActions && !dt.improvePlanActionRemoved,
          )
          .sort((a, b) => {
            if (a.improvePlanActionDueDays === b.improvePlanActionDueDays) {
              return 0;
            }

            // nulls sort after anything else
            if (a.improvePlanActionDueDays === null) {
              return 1;
            }

            if (b.improvePlanActionDueDays === null) {
              return -1;
            }

            return a.improvePlanActionDueDays > b.improvePlanActionDueDays ? 1 : -1;
          }),
      );
    } else {
      setUserImprovePlanActions(
        res
          ?.filter((dt) => dt.improvePlanActionDone === false && !dt.improvePlanActionRemoved)
          .sort((a, b) => {
            if (a.improvePlanActionDueDays === b.improvePlanActionDueDays) {
              return 0;
            }

            // nulls sort after anything else
            if (a.improvePlanActionDueDays === null) {
              return 1;
            }

            if (b.improvePlanActionDueDays === null) {
              return -1;
            }

            return a.improvePlanActionDueDays > b.improvePlanActionDueDays ? 1 : -1;
          }),
      );
    }

    setTimeout(() => {
      setLoadingData(false);
    }, 1500);

    return res;
  };

  const getWpPosts = useEffect(() => {
    const getPostsAPI = async () => {
      try {
        const userImprovePlansIds: number[] = [];
        userImprovePlansIds.push(selectedImprovePlan.questionId);
        const response = await getAllLearningByFilterCriteria({
          order: 'DESC',
          orderBy: 'contentCreatedAt',
          page: 1,
          perPage: 60,
          questionIds: userImprovePlansIds,
          tagIds: [],
        });

        const modifiedData =
          response?.data?.map((dt) => ({
            id: dt.id,
            innerImageUrl: dt.imageUrl,
            title: dt.title,
          })) || [];

        setPosts(modifiedData.slice(0, 4));
        setLoadingPosts(false);
      } catch (err) {
        setLoadingPosts(false);
        setErrorPosts(true);
      }
    };

    getPostsAPI();
  }, [selectedImprovePlan]);

  const tabButtons = userImprovePlans.map((plan: UserImprovePlanActions, index: number) => ({
    id: index + 1,
    title: plan?.question?.questionText,
    rightContent: (
      <DefaultChip
        label={planProgress.planId === plan.id ? `${planProgress.progress}%` : `${plan?.progress}%`}
        theme={plan?.question?.color}
      />
    ),
    actualItemId: plan.id || 0,
  }));

  const handleTabChange = (tab: {
    id: number;
    label: string;
    labelId: number;
    actualItemId: number;
  }) => {
    setSelectedTabDetails(tab);
    setSelectedTab(tab.labelId);

    setShowCompletedPlans(false);
  };

  return (
    <MainLayout title="Plans" secondaryNavEnabled>
      {plansLoading && loadingData ? (
        <div className="container">
          <div className={styles.loadingContainer}>
            <Skeleton variant="rounded" width="50%" height={50} />
            <Skeleton variant="rounded" width="100%" height="100vh" />
          </div>
        </div>
      ) : (
        <>
          {showCompletedPlans ? (
            <>
              <div className={`container ${styles.desktopContainer}`}>
                <div className={styles.topSectionTitleContainer}>
                  <h3 className={styles.improveTitle}>Your plans</h3>
                  {/* <p className={styles.secondaryTitle}>Take control of your wellbeing</p> */}

                  {userCompletedImprovePlans?.length > 0 && (
                    <div className={styles.filterPlansBtn}>
                      <DefaultButton
                        type="button"
                        className={styles.hsSecondaryBtn}
                        variant="primary"
                        onClick={() => {
                          history.push({
                            pathname: history.location.pathname,
                            search: '',
                          });
                          setShowCompletedPlans((prevState) => !prevState);
                        }}
                      >
                        {!showCompletedPlans ? (
                          <>
                            <span>Completed Plans</span>

                            <Img
                              src={DoubleTick}
                              alt="HS Docubletick Icon"
                              className={styles.hsStartIcon}
                            />
                          </>
                        ) : (
                          <>
                            <span>Active Plans</span>

                            <Img
                              src={ActiveIcon}
                              alt="HS Start Icon"
                              className={styles.hsStartIcon}
                            />
                          </>
                        )}
                      </DefaultButton>
                    </div>
                  )}
                </div>
                <FinishedImprovePlans plans={userCompletedImprovePlans} />
              </div>

              <div className={styles.mobileContainer}>
                {isOpen !== null && selectedImprovePlan && (
                  <div className={styles.hsSubNavigation}>
                    <MobileToggler
                      openHandler={setIsOpen}
                      isOpen={isOpen}
                      name={
                        showCompletedPlans ? (
                          'Showing completed plans'
                        ) : (
                          <div>
                            {selectedImprovePlan?.question?.questionText}&nbsp;&nbsp;&nbsp;
                            <DefaultChip
                              label={`${selectedImprovePlan.progress}%`}
                              theme={selectedImprovePlan?.question?.color}
                            />
                          </div>
                        )
                      }
                    />

                    {isOpen && (
                      <ul>
                        {userImprovePlans?.length > 0 &&
                          userImprovePlans.map((item: UserImprovePlanActions, index: number) => (
                            <li
                              key={index}
                              {...(item?.question?.questionText ===
                                selectedImprovePlan?.question?.questionText && {
                                className: styles.active,
                              })}
                            >
                              <a
                                onClick={() => {
                                  handleTabChange({
                                    id: index,
                                    label: item?.question?.questionText,
                                    labelId: index,
                                    actualItemId: item?.id,
                                  });
                                  setFilterActiveActions(false);
                                }}
                              >
                                {item?.question?.questionText}
                              </a>
                            </li>
                          ))}
                        {userCompletedImprovePlans?.length > 0 && (
                          <li>
                            <a
                              onClick={() => {
                                setShowCompletedPlans(true);
                                setFilterActiveActions(false);
                                setIsOpen(false);
                              }}
                            >
                              View completed plans
                            </a>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                )}

                <div className="container">
                  {!showCompletedPlans && (
                    <h3 className={styles.mobileTitle}>
                      Your {selectedImprovePlan?.question?.questionText?.toLowerCase() || ''}{' '}
                      wellbeing plan
                    </h3>
                  )}

                  <FinishedImprovePlans plans={userCompletedImprovePlans} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`container ${styles.desktopContainer}`}>
                <div className={styles.topSectionTitleContainer}>
                  <h3 className={styles.improveTitle}>Your plans</h3>
                  {/* <p className={styles.secondaryTitle}>Take control of your wellbeing</p> */}

                  {userCompletedImprovePlans?.length > 0 && (
                    <div className={styles.filterPlansBtn}>
                      <DefaultButton
                        type="button"
                        className={styles.hsSecondaryBtn}
                        variant="primary"
                        onClick={() => {
                          history.push({
                            pathname: history.location.pathname,
                            search: '',
                          });
                          setShowCompletedPlans((prevState) => !prevState);
                        }}
                      >
                        {!showCompletedPlans ? (
                          <>
                            <span>Completed Plans</span>

                            <Img
                              src={DoubleTick}
                              alt="HS Docubletick Icon"
                              className={styles.hsStartIcon}
                            />
                          </>
                        ) : (
                          <>
                            <span>Active Plans</span>

                            <Img
                              src={ActiveIcon}
                              alt="HS Start Icon"
                              className={styles.hsStartIcon}
                            />
                          </>
                        )}
                      </DefaultButton>
                    </div>
                  )}
                </div>

                {userImprovePlans.length === 0 && !loadingData ? (
                  <div>
                    <NoPlansSection />
                  </div>
                ) : (
                  <>
                    <div className={styles.tabsContainer}>
                      <TabButton
                        selectedTab={selectedTabDetails}
                        handleSelect={(tab: any) => {
                          handleTabChange(tab);
                          setFilterActiveActions(false);
                        }}
                        tabs={tabButtons}
                        isMobileWrapEnabled
                      />
                    </div>

                    <div className={styles.focusCardContainer}>
                      <Card className={styles.focusCard}>
                        <div className={styles.focusCardHeader}>
                          <h2 className={styles.cardTitle}>
                            Your {selectedImprovePlan?.question?.questionText?.toLowerCase() || ''}{' '}
                            wellbeing plan
                          </h2>

                          {selectedImprovePlan?.completeAt === null && (
                            <div className={styles.cardHeaderButtons}>
                              <DefaultButton
                                type="button"
                                className={styles.hsSecondaryBtn}
                                variant="secondary"
                                onClick={() => {
                                  history.push({
                                    pathname: history.location.pathname,
                                    search: '',
                                  });
                                  setFilterActiveActions((prevState) => !prevState);
                                }}
                              >
                                {viewCompleteActions === 'true'
                                  ? 'View incomplete'
                                  : 'View completed'}
                              </DefaultButton>
                              <DefaultButton
                                type="button"
                                className={styles.hsPrimaryBtn}
                                variant="primary"
                                onClick={() => setActionModalOpen(true)}
                              >
                                Add new action
                              </DefaultButton>
                            </div>
                          )}
                        </div>
                        <div className={styles.progressSection}>
                          <span>your progress</span>
                          <span>
                            {planProgress.planId === selectedImprovePlan?.id
                              ? `${planProgress.progress}`
                              : `${selectedImprovePlan?.progress}` || 0}
                            %
                          </span>
                        </div>
                        <BorderedProgressBar
                          value={
                            planProgress.planId === selectedImprovePlan?.id
                              ? planProgress.progress
                              : selectedImprovePlan?.progress
                          }
                          className={styles.progressBar}
                          barColor={
                            (selectedImprovePlan &&
                              colorMap[selectedImprovePlan?.question?.color]?.barColor) ||
                            defaultColors.barColor
                          }
                          backgroundColor={
                            (selectedImprovePlan &&
                              colorMap[selectedImprovePlan?.question?.color]?.backgroundColor) ||
                            defaultColors.backgroundColor
                          }
                        />
                        {selectedImprovePlan?.improvePlansFocusAreas?.filter(
                          (dt: { active: boolean }) => dt.active,
                        )?.length > 0 &&
                          !loadingData && (
                            <div className={styles.focussingTextContainer}>
                              <span>you are focusing on</span>
                              {selectedImprovePlan?.improvePlansFocusAreas
                                ?.filter((dt: { active: boolean }) => dt.active)
                                ?.map((item: any, index: number) => (
                                  <div
                                    key={index}
                                    className={`${styles.focusRoundedItem} ${
                                      item?.question?.color
                                        ? styles[item?.question?.color]
                                        : styles[selectedImprovePlan?.question?.color]
                                    }`}
                                  >
                                    {item?.question?.questionText}
                                  </div>
                                ))}
                            </div>
                          )}
                        <p className={styles.focusAreaDescription}>
                          {selectedImprovePlan?.question?.questionInfo}
                        </p>
                        <div className={styles.actionSection}>
                          {loadingData && (
                            <>
                              {[0, 1, 2].map((item, index) => (
                                <Skeleton
                                  key={index}
                                  variant="rounded"
                                  className={styles.skeletonActionCard}
                                />
                              ))}
                            </>
                          )}

                          {!loadingData && (
                            <>
                              {selectedImprovePlan?.progress === 100 &&
                              viewCompleteActions !== 'true' ? (
                                <>
                                  <CompletedPlansSection
                                    planName={selectedImprovePlan?.question?.questionText}
                                    activeImprovePlanCount={userImprovePlans?.length}
                                    completed={Boolean(selectedImprovePlan?.completeAt !== null)}
                                    planId={selectedImprovePlan?.id}
                                    refetchPlans={getImprovePlans}
                                  />
                                </>
                              ) : (
                                <>
                                  {userImprovePlanActions.length > 0 ? (
                                    userImprovePlanActions.map(
                                      (item: ImprovePlanAction, index: number) => {
                                        const dueDate =
                                          item.improvePlanActionDueDays >= 0
                                            ? `${Math.abs(
                                                item.improvePlanActionDueDays,
                                              )} days to go`
                                            : `${Math.abs(
                                                item.improvePlanActionDueDays,
                                              )} days overdue`;

                                        const overdue = item.improvePlanActionDueDays < 0;

                                        return (
                                          <ImproveActionCard
                                            key={index}
                                            actionData={item}
                                            className={`${styles.actionCard} ${
                                              selectedImprovePlan?.question?.color
                                                ? styles[selectedImprovePlan?.question?.color]
                                                : ''
                                            }`}
                                            title={item.actionTitle}
                                            description={item.actionDescription || ''}
                                            dueDate={dueDate}
                                            completed={item.improvePlanActionCompletedAt !== null}
                                            overdue={overdue}
                                            isRecommendedSection={false}
                                            theme={selectedImprovePlan?.question?.color}
                                            actionButtonName={item.actionButton}
                                            actionImage={item.actionImage}
                                            handleRefetch={getImprovePlans}
                                            handleActions={setUserImprovePlanActions}
                                            filterActiveActions={filterActiveActions}
                                            handleProgress={setPlanProgress}
                                          />
                                        );
                                      },
                                    )
                                  ) : (
                                    <h6 className={styles.noActionsText}>
                                      You haven’t completed any actions yet. Take a small step
                                      towards your plan today.
                                    </h6>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {selectedImprovePlan?.progress !== 100 && (
                          <>
                            <div className={styles.blogPostSection}>
                              <h5>Recommended for you</h5>
                              <Grid container spacing={4} className={styles.learningItemsContainer}>
                                {posts &&
                                  posts.length > 0 &&
                                  posts.map((item, index: number) => (
                                    <Grid item xs={12} md={3} key={index}>
                                      <LearningArticleCard
                                        data={item}
                                        loading={loadingPosts}
                                        sectionType={item.id === -999 ? 'allLearnings' : 'posts'}
                                        navigateTo="oldPosts"
                                      />
                                    </Grid>
                                  ))}
                              </Grid>

                              {posts?.length === 0 && !loadingPosts && (
                                <p className={styles.noPostsText}>No posts</p>
                              )}
                            </div>

                            {companyConfigData.data && companyConfigData.data.expertsEnabled && (
                              <div className={styles.expertSection}>
                                <h5>Book a session with an expert</h5>
                                <ExpertsCarousel experts={experts} loading={expertsLoading} />

                                {experts?.total === 0 && !expertsLoading && (
                                  <p className={styles.noPostsText}>No experts</p>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </Card>
                    </div>
                  </>
                )}
              </div>

              {userImprovePlans.length === 0 && !loadingData ? (
                <div className={styles.mobileContainer}>
                  <NoPlansSection />
                </div>
              ) : (
                <>
                  <div className={styles.mobileContainer}>
                    {isOpen !== null && (selectedImprovePlan || showCompletedPlans) && (
                      <div className={styles.hsSubNavigation}>
                        <MobileToggler
                          openHandler={setIsOpen}
                          isOpen={isOpen}
                          name={
                            <div>
                              {selectedImprovePlan?.question?.questionText}&nbsp;&nbsp;&nbsp;
                              <DefaultChip
                                label={`${selectedImprovePlan.progress}%`}
                                theme={selectedImprovePlan?.question?.color}
                              />
                            </div>
                          }
                        />

                        {isOpen && (
                          <ul>
                            {userImprovePlans?.length > 0 &&
                              !showCompletedPlans &&
                              userImprovePlans.map(
                                (item: UserImprovePlanActions, index: number) => (
                                  <li
                                    key={index}
                                    {...(item?.question?.questionText ===
                                      selectedImprovePlan?.question?.questionText && {
                                      className: styles.active,
                                    })}
                                  >
                                    <a
                                      onClick={() => {
                                        handleTabChange({
                                          id: index,
                                          label: item?.question?.questionText,
                                          labelId: index,
                                          actualItemId: item?.id,
                                        });
                                        setFilterActiveActions(false);
                                      }}
                                    >
                                      {item?.question?.questionText}
                                    </a>
                                  </li>
                                ),
                              )}
                            {userCompletedImprovePlans?.length > 0 && (
                              <li>
                                <a
                                  onClick={() => {
                                    setShowCompletedPlans(true);
                                    setFilterActiveActions(false);
                                    setIsOpen(false);
                                  }}
                                >
                                  View completed plans
                                </a>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    )}

                    <div className={`container ${showCompletedPlans ? styles.hideOnMobile : ''}`}>
                      <div className={styles.mobileTop}>
                        <h3 className={styles.mobileTitle}>
                          Your {selectedImprovePlan?.question?.questionText?.toLowerCase() || ''}{' '}
                          wellbeing plan
                        </h3>
                      </div>

                      <div className={styles.mobileTopButtons}>
                        {selectedImprovePlan?.completeAt === null && (
                          <div className={styles.primaryFullWidthBtn}>
                            <DefaultButton
                              type="button"
                              className={`${styles.hsSecondaryBtn} ${styles.fullWidthMobile}`}
                              variant="secondary"
                              onClick={() => {
                                history.push({
                                  pathname: history.location.pathname,
                                  search: '',
                                });
                                setFilterActiveActions((prevState) => !prevState);
                              }}
                            >
                              {viewCompleteActions === 'true'
                                ? 'View incomplete'
                                : 'View completed'}
                            </DefaultButton>
                          </div>
                        )}
                        <div className={styles.primaryFullWidthBtn}>
                          <DefaultButton
                            type="button"
                            className={`${styles.hsPrimaryBtn} ${styles.fullWidthMobile}`}
                            variant="secondary"
                            onClick={() => setActionModalOpen(true)}
                          >
                            Add new action
                          </DefaultButton>
                        </div>
                      </div>

                      <div className={styles.progressSection}>
                        <span>your progress</span>
                        <span>
                          {planProgress.planId === selectedImprovePlan?.id
                            ? `${planProgress.progress}`
                            : `${selectedImprovePlan?.progress}` || 0}
                          %
                        </span>
                      </div>

                      <BorderedProgressBar
                        value={
                          planProgress.planId === selectedImprovePlan?.id
                            ? planProgress.progress
                            : selectedImprovePlan?.progress
                        }
                        className={styles.progressBar}
                        barColor={
                          (selectedImprovePlan &&
                            colorMap[selectedImprovePlan?.question?.color]?.barColor) ||
                          defaultColors.barColor
                        }
                        backgroundColor={
                          (selectedImprovePlan &&
                            colorMap[selectedImprovePlan?.question?.color]?.backgroundColor) ||
                          defaultColors.backgroundColor
                        }
                      />

                      {selectedImprovePlan?.improvePlansFocusAreas?.filter(
                        (dt: { active: boolean }) => dt.active,
                      )?.length > 0 &&
                        !loadingData && (
                          <div className={styles.focussingTextContainerMobile}>
                            <span>you are focusing on</span>
                            <div className={styles.focuses}>
                              {selectedImprovePlan?.improvePlansFocusAreas
                                ?.filter((dt: { active: boolean }) => dt.active)
                                ?.map((item: any, index: number) => (
                                  <div
                                    key={index}
                                    className={`${styles.focusRoundedItem} ${
                                      item?.question?.color
                                        ? styles[item?.question?.color]
                                        : styles[selectedImprovePlan?.question?.color]
                                    }`}
                                  >
                                    {item?.question?.questionText}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}

                      <p className={styles.focusAreaDescription}>
                        {selectedImprovePlan?.question?.questionInfo}
                      </p>

                      <div className={styles.actionSection}>
                        {loadingData && (
                          <>
                            {[0, 1, 2].map((item, index) => (
                              <Skeleton
                                key={index}
                                variant="rounded"
                                className={styles.skeletonActionCard}
                              />
                            ))}
                          </>
                        )}

                        {!loadingData && (
                          <>
                            {selectedImprovePlan?.progress === 100 ? (
                              <>
                                <CompletedPlansSection
                                  planName={selectedImprovePlan?.question?.questionText}
                                  activeImprovePlanCount={userImprovePlans?.length}
                                  completed={Boolean(selectedImprovePlan?.completeAt !== null)}
                                  planId={selectedImprovePlan?.id}
                                  refetchPlans={getImprovePlans}
                                />
                              </>
                            ) : (
                              <>
                                {userImprovePlanActions.length > 0 ? (
                                  userImprovePlanActions.map(
                                    (item: ImprovePlanAction, index: number) => {
                                      // Calculate due date and overdue status
                                      const dueDate =
                                        item.improvePlanActionDueDays >= 0
                                          ? `${Math.abs(item.improvePlanActionDueDays)} days to go`
                                          : `${Math.abs(
                                              item.improvePlanActionDueDays,
                                            )} days overdue`;

                                      const overdue = item.improvePlanActionDueDays < 0;

                                      return (
                                        <ImproveActionCard
                                          key={index}
                                          actionData={item}
                                          className={`${styles.actionCard} ${
                                            selectedImprovePlan?.question?.color
                                              ? styles[selectedImprovePlan?.question?.color]
                                              : ''
                                          }`}
                                          title={item.actionTitle}
                                          description={item.actionDescription || ''}
                                          dueDate={dueDate}
                                          completed={item.improvePlanActionCompletedAt !== null}
                                          overdue={overdue}
                                          isRecommendedSection={false}
                                          theme={selectedImprovePlan?.question?.color}
                                          actionButtonName={item.actionButton}
                                          actionImage={item.actionImage}
                                          handleRefetch={getImprovePlans}
                                          handleActions={setUserImprovePlanActions}
                                          filterActiveActions={filterActiveActions}
                                          handleProgress={setPlanProgress}
                                        />
                                      );
                                    },
                                  )
                                ) : (
                                  <h6 className={styles.noActionsText}>
                                    You haven’t completed any actions yet. Take a small step towards
                                    your plan today.
                                  </h6>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>

                      <div className={styles.blogPostSection}>
                        <h5>Recommended content for you</h5>
                        <div className={styles.blogCardSection}>
                          <Grid container spacing={4} className={styles.learningItemsContainer}>
                            {posts &&
                              posts.length > 0 &&
                              posts.map((item, index: number) => (
                                <Grid item xs={12} md={3} key={index}>
                                  <LearningArticleCard
                                    data={item}
                                    loading={loadingPosts}
                                    sectionType={item.id === -999 ? 'allLearnings' : 'posts'}
                                    navigateTo="oldPosts"
                                  />
                                </Grid>
                              ))}
                          </Grid>

                          {posts?.length === 0 && !loadingPosts && (
                            <p className={styles.noPostsText}>No posts</p>
                          )}
                        </div>
                      </div>

                      {companyConfigData.data && companyConfigData.data.expertsEnabled && (
                        <div className={styles.expertSection}>
                          <h5>Book a session with an expert</h5>
                          <ExpertsCarousel experts={experts} loading={expertsLoading} />

                          {experts?.total === 0 && !expertsLoading && (
                            <p className={styles.noPostsText}>No experts</p>
                          )}
                        </div>
                      )}
                    </div>
                    {selectedImprovePlan?.completeAt === null && (
                      <div
                        className={styles.floatingActionButton}
                        onClick={() => setActionModalOpen(true)}
                      >
                        <span>+</span>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      <Modal
        isOpen={actionModalOpen}
        onRequestClose={() => setActionModalOpen(false)}
        contentLabel="Improve Action Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '90%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() => setActionModalOpen(false)}
          hasBackground
          title="Create your action"
        >
          <CreateImprovePlan
            closeModal={() => setActionModalOpen(false)}
            improvePlan={`${selectedImprovePlan?.question?.questionText} wellbeing`}
            id={selectedImprovePlan?.id}
            handleRefetch={getImprovePlans}
          />
        </ModalContent>
      </Modal>
    </MainLayout>
  );
};

interface TabDetailsInterface {
  id: number;
  label: string;
  labelId: number;
  actualItemId?: number;
}

interface ActionPlan {
  id: number;
  userId: number;
  questionId: number;
  done: boolean;
  improvePlan: string;
  improvePlanDescription: string;
  completeAt: string | null;
  progress: number;
  questionColor: string;
  questionIcon: string;
  createdAt: string;
  updatedAt: string;
}

export default ImprovePage;
