import GraphExample from '@assets/images/graph-example.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import NewHomeGauge from '@components/Charts/Gauge/newHomeGauge';
import DashboardAreaChart from '@components/Molecules/DashboardAreaChart';
import ExploreSectionAreaChart from '@components/Molecules/ExploreSectionAreaChart';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { Skeleton } from '@mui/material';
import { DEFAULT_HS_BLUE_COLOR, darkenColor, getBackgroundStyle } from 'custom.d';
import React from 'react';
import { RootState } from 'store';
import 'swiper/css';
import 'swiper/css/pagination';
import TipsIcon from '../../../../assets/images/tips-icon.svg';
import styles from './index.module.scss';

const ScoreGaugeSection: React.FunctionComponent<ScoreGaugeSectionProps> = ({
  loading,
  questionMarkIcon,
  button,
  handleOpen,
  exploreSectionEnabled,
  handleExplore,
  score,
  assessmentScores,
  handleStarModal,
  assessmentScoresLoading,
  scoreChartData,
}) => {
  const hasEmptyData = assessmentScores.some((item: { data: any }) => item.data.length === 0);
  const { isDesktop } = useWindowDimensions();
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig.data);

  const backgroundStyle = getBackgroundStyle(
    companyConfigData.dashboardBgColour,
    DEFAULT_HS_BLUE_COLOR,
    13,
    8,
  );

  return (
    <div
      className={`${styles.scoreGaugeContainer} ${
        exploreSectionEnabled ? styles.exploreSectionEnabled : ''
      }`}
      style={backgroundStyle}
    >
      <div className={styles.bodyContainer}>
        <div className={styles.top}>
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={40} />
              <Skeleton variant="text" width="10%" height={40} />
            </>
          ) : (
            <>
              <h5>Your wellbeing score</h5>
              <div
                className={styles.questionmark}
                onClick={() => {
                  handleOpen({
                    open: true,
                    top: '150px',
                    right: '53%',
                    firstDescription:
                      'Your score is worked out using the Hapstar HPS (Happiness Promoter Score) methodology. This scoring system was worked out by experts, who have assessed hundreds of thousands of happiness data points and applied the correct weightings to accurately determine how happy you are in the form of a number.',
                    description:
                      'The scores you inputted across all happiness drivers are translated to either a happiness promoter or detractor. These are then weighted and aggregated to determine your score. You will find that happiness swings dramatically from the mid-point (50) but becomes harder and harder to reach the end points (0 and 100). This is true in real life as well. It’s much easier to feel happier when you’re middling, than it is when you’re already extremely happy.',
                    title: 'This is your wellbeing score!',
                  });
                }}
              >
                <Img
                  src={questionMarkIcon}
                  alt="question mark icon"
                  className={styles.queMarkicon}
                />
              </div>
            </>
          )}
        </div>

        {loading ? (
          <Skeleton variant="circular" width={180} height={180} className={styles.loadPie} />
        ) : (
          <>
            {score === undefined || score === null || Number.isNaN(score) ? (
              <div className={styles.emptyDataBox}>
                <div className={styles.emptyCircle}>
                  <p className={styles.emptyText}>You haven’t recorded wellbeing score yet</p>
                </div>
              </div>
            ) : (
              <NewHomeGauge score={score || 0} />
            )}
          </>
        )}

        <div className={styles.buttons}>
          {loading ? (
            <>
              <Skeleton variant="rounded" width="20%" className={styles.loadBtn} />
              <Skeleton variant="rounded" width="20%" className={styles.loadBtn} />
            </>
          ) : (
            <>
              <DefaultButton
                variant="primary"
                className={styles.hsPrimaryBtn}
                onClick={() => handleStarModal(true)}
              >
                Check in
              </DefaultButton>
              <DefaultButton
                variant="secondary"
                className={styles.hsPrimaryWhite}
                onClick={() => handleExplore(!exploreSectionEnabled)}
              >
                {exploreSectionEnabled ? 'Hide' : 'Explore'}
              </DefaultButton>
            </>
          )}
        </div>
      </div>
      {exploreSectionEnabled && (
        <>
          <div className={styles.topContent}>
            <Img src={TipsIcon} alt="Tips" className={styles.tipsIcon} />
            <p>
              {isDesktop
                ? 'Track your progress across 6 key wellbeing pillars'
                : 'Your wellbeing score over time'}
            </p>
          </div>

          <div className={styles.areaChartContainer}>
            <DashboardAreaChart
              loading={assessmentScoresLoading}
              scoreChartData={scoreChartData}
              handleStarModal={handleStarModal}
            />
          </div>

          {!isDesktop && (
            <div className={styles.trackText}>
              <p>Track your progress across 6 key wellbeing pillars</p>
            </div>
          )}

          {hasEmptyData || assessmentScores.length === 0 ? (
            <div className={styles.mobileExploreSectionEmpty} onClick={() => handleStarModal(true)}>
              <div className={styles.emptyChart}>
                <h3>
                  Let&apos;s start building your well-being journey! <br />
                  <span onClick={() => handleStarModal(true)}>Check in</span> and start building
                  your score
                </h3>
                <Img src={GraphExample} alt="HS graph example" className={styles.graphImage} />
              </div>
            </div>
          ) : (
            <div className={styles.mobileExploreSection}>
              <ExploreSectionAreaChart loading={loading} data={assessmentScores} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

ScoreGaugeSection.defaultProps = {
  loading: false,
  button: 'Start Now',
  assessmentScoresLoading: true,
};

interface ScoreGaugeSectionProps {
  loading?: boolean;
  questionMarkIcon: string;
  button?: string;
  handleOpen: (arg1: any) => void;
  exploreSectionEnabled: boolean;
  handleExplore: (arg1: boolean) => void;
  score: number | null;
  assessmentScores: any;
  handleStarModal: (arg0: boolean) => void;
  assessmentScoresLoading?: boolean;
  scoreChartData: { scores: number[]; dates: string[] };
}

export default ScoreGaugeSection;
