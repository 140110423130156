import { createImprovePlan, updateActionsByImprovePlanId } from '@api/actionPlan';
import { addAdvisorFocusAreas } from '@api/focusArea';
import backIcon from '@assets/images/icons/chevron-left.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useGoNative from '@hooks/useGoNative';
import { CircularProgress } from '@mui/material';
import { handleCompletePilarEmail } from '@utils/api.functions.util';
import { ImprovePlanTypes } from 'custom.d';
import React, { useContext, useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { updateHelpAvailability } from 'store/Actions/HelpAndSpeakupAction';
import { DefaultDeepDiveValues, deepDiveWizardActions } from 'store/Slices/DeepDiveWizardSlice';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import styles from './index.module.scss';

const DeepDiveWizardFooter: React.FunctionComponent<DeepDiveWizardFooterInterface> = ({
  handlePreviousCategory,
  handleNextCategory,
  activeCategoryIndex,
  activeCategoryId,
  isNextButtonEnabled,
}) => {
  const isMobileApp = useGoNative();
  const reduxDispatch = useAppDispatch();
  const history = useHistory();
  const { collapseSidebar } = useProSidebar();
  const deepDiveWizard = useAppSelector((state: RootState) => state.deepDiveWizard);
  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [completingPilar, setCompletingPilar] = useState<boolean>(false);
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);

  useEffect(() => {
    let selectedFocusAreas = 0;

    deepDiveWizard.selectedAndParsedUpdatedCategories.forEach((category) => {
      selectedFocusAreas += category.questions.filter((qs) => qs.isSelected).length;
    });

    setSelectedCount(selectedFocusAreas);
  }, [deepDiveWizard.selectedAndParsedUpdatedCategories]);

  const handleCancel = () => {
    collapseSidebar(true);
    reduxDispatch(deepDiveWizardActions.resetWizard());
    history.push('/');
  };

  // TODO: Deprecated
  const handleSkip = async () => {
    if (
      reduxStarScores.selectedFocusAreas.length > 0 &&
      reduxStarScores.currentStep !== 'NO_ACTIVE_PLANS'
    ) {
      if (
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id
      ) {
        await updateActionsByImprovePlanId(
          reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id,
          ImprovePlanTypes.DEEP_DIVE,
          true,
        );
      }

      if (companyConfig.talkToAdvisor) {
        reduxDispatch(starScoresSliceActions.setSubNavigationStep('hasPlanAndUpdatedFocusAreas'));
        reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
        reduxDispatch(deepDiveWizardActions.updateWizardSubStep('TALK_TO_ADVISOR_MESSAGE'));
      } else if (
        !companyConfig.talkToAdvisor ||
        companyConfig.talkToAdvisor === undefined ||
        companyConfig.talkToAdvisor === null
      ) {
        reduxDispatch(starScoresSliceActions.setSubNavigationStep('hasPlanAndUpdatedFocusAreas'));
        reduxDispatch(deepDiveWizardActions.updateWizardStep('ACTIONS'));
        reduxDispatch(deepDiveWizardActions.updateWizardSubStep('FINISH'));
      } else {
        reduxDispatch(starScoresSliceActions.setSubNavigationStep('hasPlanAndUpdatedFocusAreas'));
        reduxDispatch(deepDiveWizardActions.updateWizardStep('ACTIONS'));
        reduxDispatch(deepDiveWizardActions.updateWizardSubStep('FINISH'));
      }
    } else {
      if (
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id
      ) {
        await updateActionsByImprovePlanId(
          reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id,
          ImprovePlanTypes.DEEP_DIVE,
          true,
        );
      }

      reduxDispatch(starScoresSliceActions.setSubNavigationStep('noPlanAndFocusAreas'));
      reduxDispatch(deepDiveWizardActions.updateWizardStep('ACTIONS'));
      reduxDispatch(deepDiveWizardActions.updateWizardSubStep('FINISH'));
    }
  };

  const handleFocusNavigation = async () => {
    if (
      reduxStarScores.currentStep !== 'NO_ACTIVE_PLANS' &&
      reduxStarScores.selectedFocusAreas.length !== 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
        deepDiveWizard.assessmentDetails?.assessment_id || null,
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }

      await reduxDispatch(starScoresSliceActions.setCreatedImprovePlanDetails(res));
    }

    if (
      reduxStarScores.currentStep === 'NO_ACTIVE_PLANS' &&
      reduxStarScores.selectedFocusAreas.length > 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
        deepDiveWizard.assessmentDetails?.assessment_id || null,
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }

      await reduxDispatch(starScoresSliceActions.setCreatedImprovePlanDetails(res));
    }

    if (
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id &&
      reduxStarScores.selectedFocusAreas.length === 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
        deepDiveWizard.assessmentDetails?.assessment_id || null,
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }
    }
  };

  const handlePlanNavigation = async () => {
    switch (reduxStarScores.currentStep) {
      case 'NO_ACTIVE_PLANS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'noPlanAndFocusAreas'
              : 'planCreatedWithFocusAreas',
          ),
        );
        break;
      case 'HAS_PLANS_NO_FOCUS_AREAS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'hasPlanNoFocusAreas'
              : 'hasPlanAndUpdatedFocusAreas',
          ),
        );
        break;
      case 'HAS_BOTH_PLANS_AND_FOCUS_AREAS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'hasPlanAndRemovedFocusAreas'
              : 'hasPlanAndUpdatedFocusAreas',
          ),
        );
        break;
      default:
        break;
    }
  };

  const handleComplete = async () => {
    collapseSidebar(true);
    reduxDispatch(updateHelpAvailability(false));

    if (
      reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' &&
      reduxStarScores.selectedFocusAreas.length === 0
    ) {
      handleCompletePilarEmail(activeSubNav.id, null);
      history.push(`/stars`);
    } else if (reduxStarScores.createdImprovementPlan) {
      handleCompletePilarEmail(activeSubNav.id, reduxStarScores.createdImprovementPlan.id);
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.createdImprovementPlan.id}&improvePlan=${undefined}&viewCompletedActions=false`,
      );
    } else if (
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans?.length > 0
    ) {
      handleCompletePilarEmail(
        activeSubNav.id,
        reduxStarScores.deepDiveQuestionWithPlans[0].question.improvePlans[0].id,
      );
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.deepDiveQuestionWithPlans[0].question.improvePlans[0].id}&improvePlan=${reduxStarScores.deepDiveQuestionWithPlans[0].question?.questionText}&viewCompletedActions=false`,
      );
    }
  };

  const handleSaveAdvisorAreas = async () => {
    try {
      const areaIds = reduxStarScores.advisorSelectedFocusAreas.map((area) => area.id);
      if (areaIds?.length > 0) {
        const req = await addAdvisorFocusAreas(areaIds);

        if (req?.success) {
          reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
          reduxDispatch(deepDiveWizardActions.updateWizardSubStep('TALK_TO_ADVISOR_COMPLETION'));
        } else {
          const message = req?.responseObject?.[0]?.message || '';
          toast.error(message || 'Error occurred, try again!');
          reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
          reduxDispatch(deepDiveWizardActions.updateWizardSubStep('TALK_TO_ADVISOR_COMPLETION'));
        }
      } else {
        toast.error('Please select at least one area');
      }
    } catch (e: any) {
      toast.error(e?.message || 'Error occurred, try again!');
    }
  };

  return (
    <>
      <div className={styles.hsStepWizardFooterDesktop}>
        {(() => {
          switch (deepDiveWizard.wizardStep) {
            case DefaultDeepDiveValues.SCORING_STEP:
              return (
                <>
                  {(() => {
                    switch (deepDiveWizard.wizardSubStep) {
                      case DefaultDeepDiveValues.START_SUB_STEP:
                        return <div className={styles.hsStepWizardStartFooter} />;

                      case DefaultDeepDiveValues.QUESTIONS_SUB_STEP:
                        return (
                          <>
                            <DefaultButton
                              variant="secondary"
                              type="button"
                              onClick={handlePreviousCategory}
                              disabled={activeCategoryIndex === 0}
                            >
                              Back
                            </DefaultButton>
                            <div className={styles.hsStepWizardFooterInner}>
                              <DefaultButton
                                variant="secondary"
                                type="button"
                                onClick={handleCancel}
                              >
                                Cancel
                              </DefaultButton>

                              <DefaultButton
                                variant="primary"
                                type="button"
                                onClick={handleNextCategory}
                                disabled={!isNextButtonEnabled}
                              >
                                Next
                              </DefaultButton>
                            </div>
                          </>
                        );

                      default:
                        return null;
                    }
                  })()}
                </>
              );

            case DefaultDeepDiveValues.REVIEWING_STEP:
              return (
                <>
                  {(() => {
                    switch (deepDiveWizard.wizardSubStep) {
                      case DefaultDeepDiveValues.SCORES_SUBMIT_SUB_STEP:
                        return (
                          <div className={styles.singleBtnContainer}>
                            <DefaultButton
                              variant="primary"
                              type="button"
                              onClick={handleNextCategory}
                              disabled={!isNextButtonEnabled}
                            >
                              Continue
                            </DefaultButton>
                          </div>
                        );

                      case DefaultDeepDiveValues.INSTANT_HELP_SUB_STEP:
                        return (
                          <div className={styles.singleBtnContainer}>
                            <DefaultButton
                              variant="primary"
                              type="button"
                              onClick={() => {
                                reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
                                reduxDispatch(
                                  deepDiveWizardActions.updateWizardSubStep('FOCUS_AREAS'),
                                );
                              }}
                            >
                              Skip to Set Actions
                            </DefaultButton>
                          </div>
                        );

                      case DefaultDeepDiveValues.FOCUS_AREAS_SUB_STEP:
                        return (
                          <>
                            <div className={styles.focusAreaFooterSection}>
                              <div className={styles.left}>
                                <p className={styles.selected}>
                                  Select up to three areas that you’d like to improve on
                                </p>
                                <p className={styles.count}>Selected: {selectedCount}</p>
                              </div>

                              <div className={styles.right}>
                                {/* REMOVED AS REQUESTED, COMMENTED IF FUTURE NEEDED
                                <DefaultButton
                                  variant="secondary"
                                  type="button"
                                  onClick={async () => {
                                    await handleCompletePilar();
                                    await handleSkip();
                                    reduxDispatch(
                                      deepDiveWizardActions.updateWizardStep('ACTIONS'),
                                    );
                                    reduxDispatch(
                                      deepDiveWizardActions.updateWizardSubStep('FINISH'),
                                    );
                                    reduxDispatch(
                                      deepDiveWizardActions.setUserSkippedFocusSelection(true),
                                    );
                                  }}
                                >
                                  Skip
                                </DefaultButton> */}
                                <DefaultButton
                                  variant="primary"
                                  type="button"
                                  onClick={() => {
                                    handleNextCategory();
                                    handleFocusNavigation();
                                    handlePlanNavigation();
                                  }}
                                  disabled={!isNextButtonEnabled}
                                >
                                  Continue
                                </DefaultButton>
                              </div>
                            </div>
                          </>
                        );

                      case DefaultDeepDiveValues.TALK_TO_ADVISOR_MESSAGE_SUB_STEP:
                        return null;

                      case DefaultDeepDiveValues.TALK_TO_ADVISOR_AREA_SELECTION_SUB_STEP:
                        return (
                          <div className={styles.hsStepWizardFooterStart}>
                            <DefaultButton
                              variant="secondary"
                              type="button"
                              onClick={() => {
                                handleSaveAdvisorAreas();
                              }}
                            >
                              Back
                            </DefaultButton>

                            <DefaultButton
                              variant="primary"
                              type="button"
                              onClick={() => {
                                reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
                                reduxDispatch(
                                  deepDiveWizardActions.updateWizardSubStep(
                                    'TALK_TO_ADVISOR_COMPLETION',
                                  ),
                                );
                              }}
                              disabled={deepDiveWizard.isAdvisorDiscussionContinueDisabled}
                            >
                              Continue
                            </DefaultButton>
                          </div>
                        );

                      case DefaultDeepDiveValues.TALK_TO_ADVISOR_COMPLETION_SUB_STEP:
                        return (
                          <DefaultButton
                            variant="primary"
                            type="button"
                            onClick={() => {
                              handleComplete();
                              reduxDispatch(starScoresSliceActions.resetStarScores());
                              reduxDispatch(deepDiveWizardActions.resetWizard());
                            }}
                          >
                            Take me to my plan
                          </DefaultButton>
                        );

                      default:
                        return (
                          <div>
                            <p />
                          </div>
                        );
                    }
                  })()}
                </>
              );

            case DefaultDeepDiveValues.ACTIONS_STEP:
              return (
                <div className={styles.singleBtnContainer}>
                  <DefaultButton
                    variant="primary"
                    type="button"
                    onClick={() => {
                      if (
                        companyConfig.talkToAdvisor &&
                        reduxStarScores.selectedFocusAreas.length > 0
                      ) {
                        reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
                        reduxDispatch(
                          deepDiveWizardActions.updateWizardSubStep('TALK_TO_ADVISOR_MESSAGE'),
                        );
                      } else {
                        handleComplete();
                        reduxDispatch(starScoresSliceActions.resetStarScores());
                        reduxDispatch(deepDiveWizardActions.resetWizard());
                      }
                    }}
                  >
                    {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' &&
                    reduxStarScores.selectedFocusAreas.length === 0
                      ? 'Finish'
                      : 'Take me to my plan'}
                  </DefaultButton>
                </div>
              );

            default:
              return (
                <div>
                  <p>Default step</p>
                </div>
              );
          }
        })()}
      </div>

      <div className={styles.hsStepWizardFooterGamma}>
        {(() => {
          switch (deepDiveWizard.wizardStep) {
            case DefaultDeepDiveValues.SCORING_STEP:
              return (
                <>
                  {(() => {
                    switch (deepDiveWizard.wizardSubStep) {
                      case DefaultDeepDiveValues.START_SUB_STEP:
                        return (
                          <div className={styles.hsStepWizardFooterStart}>
                            <DefaultButton variant="secondary" type="button" onClick={handleCancel}>
                              Cancel
                            </DefaultButton>

                            <DefaultButton
                              variant="primary"
                              type="button"
                              onClick={() => {
                                reduxDispatch(deepDiveWizardActions.updateWizardStep('SCORE'));
                                reduxDispatch(
                                  deepDiveWizardActions.updateWizardSubStep('QUESTIONS'),
                                );
                              }}
                            >
                              Let&apos;s begin
                            </DefaultButton>
                          </div>
                        );
                      case DefaultDeepDiveValues.QUESTIONS_SUB_STEP:
                        return (
                          <>
                            <div
                              onClick={handlePreviousCategory}
                              className={`${styles.commonBackContainerStyle} ${styles.backIconContainerBlue}`}
                            >
                              <Img src={backIcon} alt="back icon" />
                            </div>

                            <div className={styles.hsStepWizardFooterInner}>
                              <DefaultButton
                                variant="secondary"
                                type="button"
                                onClick={handleCancel}
                              >
                                Cancel
                              </DefaultButton>

                              <DefaultButton
                                variant="primary"
                                type="button"
                                onClick={handleNextCategory}
                                disabled={!isNextButtonEnabled}
                              >
                                Next
                              </DefaultButton>
                            </div>
                          </>
                        );

                      default:
                        return null;
                    }
                  })()}
                </>
              );

            case DefaultDeepDiveValues.REVIEWING_STEP:
              return (
                <>
                  {(() => {
                    switch (deepDiveWizard.wizardSubStep) {
                      case DefaultDeepDiveValues.SCORES_SUBMIT_SUB_STEP:
                        return (
                          <DefaultButton
                            variant="primary"
                            type="button"
                            onClick={handleNextCategory}
                          >
                            Continue
                          </DefaultButton>
                        );

                      case DefaultDeepDiveValues.INSTANT_HELP_SUB_STEP:
                        return (
                          <DefaultButton
                            variant="primary"
                            type="button"
                            onClick={() => {
                              reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
                              reduxDispatch(
                                deepDiveWizardActions.updateWizardSubStep('FOCUS_AREAS'),
                              );
                            }}
                          >
                            Skip to Set Actions
                          </DefaultButton>
                        );

                      case DefaultDeepDiveValues.FOCUS_AREAS_SUB_STEP:
                        return (
                          <>
                            <div className={styles.focusAreaFooterSection}>
                              <div className={styles.left}>
                                <p className={styles.selected}>
                                  Select up to three areas that you’d like to improve on
                                </p>
                                <p className={styles.count}>Selected: {selectedCount}</p>
                              </div>

                              <div className={styles.right}>
                                {/* REMOVED AS REQUESTED, COMMENTED IF FUTURE NEEDED
                                <DefaultButton
                                  variant="secondary"
                                  type="button"
                                  onClick={async () => {
                                    await handleCompletePilar();
                                    await handleSkip();
                                    reduxDispatch(
                                      deepDiveWizardActions.updateWizardStep('ACTIONS'),
                                    );
                                    reduxDispatch(
                                      deepDiveWizardActions.updateWizardSubStep('FINISH'),
                                    );
                                    reduxDispatch(
                                      deepDiveWizardActions.setUserSkippedFocusSelection(true),
                                    );
                                  }}
                                  disabled={completingPilar}
                                >
                                  Skip
                                </DefaultButton> */}
                                <DefaultButton
                                  variant="primary"
                                  type="button"
                                  onClick={async () => {
                                    await handleFocusNavigation();
                                    await handlePlanNavigation();
                                    await handleNextCategory();
                                  }}
                                  disabled={completingPilar}
                                >
                                  {completingPilar ? (
                                    <CircularProgress size={20} color="inherit" />
                                  ) : (
                                    'Continue'
                                  )}
                                </DefaultButton>
                              </div>
                            </div>
                          </>
                        );

                      case DefaultDeepDiveValues.TALK_TO_ADVISOR_MESSAGE_SUB_STEP:
                        return null;

                      case DefaultDeepDiveValues.TALK_TO_ADVISOR_AREA_SELECTION_SUB_STEP:
                        return (
                          <div className={styles.hsStepWizardFooterStart}>
                            <DefaultButton
                              variant="secondary"
                              type="button"
                              onClick={() => {
                                reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
                                reduxDispatch(
                                  deepDiveWizardActions.updateWizardSubStep(
                                    'TALK_TO_ADVISOR_MESSAGE',
                                  ),
                                );
                              }}
                            >
                              Back
                            </DefaultButton>

                            <DefaultButton
                              variant="primary"
                              type="button"
                              onClick={() => {
                                handleSaveAdvisorAreas();
                              }}
                              disabled={deepDiveWizard.isAdvisorDiscussionContinueDisabled}
                            >
                              Continue
                            </DefaultButton>
                          </div>
                        );

                      case DefaultDeepDiveValues.TALK_TO_ADVISOR_COMPLETION_SUB_STEP:
                        return (
                          <DefaultButton
                            variant="primary"
                            type="button"
                            onClick={() => {
                              handleComplete();
                              reduxDispatch(starScoresSliceActions.resetStarScores());
                              reduxDispatch(deepDiveWizardActions.resetWizard());
                            }}
                          >
                            Take me to my plan
                          </DefaultButton>
                        );

                      default:
                        return (
                          <div>
                            <p />
                          </div>
                        );
                    }
                  })()}
                </>
              );

            case DefaultDeepDiveValues.ACTIONS_STEP:
              return (
                <>
                  <DefaultButton
                    variant="primary"
                    type="button"
                    onClick={() => {
                      if (
                        companyConfig.talkToAdvisor &&
                        reduxStarScores.selectedFocusAreas.length > 0
                      ) {
                        reduxDispatch(deepDiveWizardActions.updateWizardStep('REVIEW'));
                        reduxDispatch(
                          deepDiveWizardActions.updateWizardSubStep('TALK_TO_ADVISOR_MESSAGE'),
                        );
                      } else {
                        handleComplete();
                        reduxDispatch(starScoresSliceActions.resetStarScores());
                        reduxDispatch(deepDiveWizardActions.resetWizard());
                      }
                    }}
                  >
                    {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' &&
                    reduxStarScores.selectedFocusAreas.length === 0
                      ? 'Finish'
                      : 'Take me to my plan'}
                  </DefaultButton>
                </>
              );
            default:
              return (
                <div>
                  <p>Default step</p>
                </div>
              );
          }
        })()}
      </div>
    </>
  );
};

interface DeepDiveWizardFooterInterface {
  handlePreviousCategory: () => void;
  handleNextCategory: () => void;
  activeCategoryIndex: number;
  activeCategoryId: number;
  isNextButtonEnabled: boolean;
}

export default DeepDiveWizardFooter;
