import backIcon from '@assets/images/icons/chevron-left.svg';
import questioMarkIcon from '@assets/images/icons/svgviewer-output.svg';
import AnimatedStarOpacity from '@components/AnimatedStarOpacity';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import PageTitle from '@components/PageTitle';
import { CreateStarContext } from '@context/CreateStarContext';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { handleCompletePilarEmail } from '@utils/api.functions.util';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useWizard } from 'react-use-wizard';
import { RootState } from 'store';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import styles from './index.module.scss';

const StepWizardHeader: React.FunctionComponent<StepWizardHeaderInterface> = (props) => {
  const { categoryNames, handleAssessmentData, customButtonName } = props;
  const reduxDispatch = useAppDispatch();
  const { activeStep, isFirstStep, isLastStep, stepCount, nextStep } = useWizard();
  const { categoryQuestion, setCurrentStep } = useContext(CreateStarContext);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [editPage, setEditPage] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>('Overall');
  const { isMobile } = useWindowDimensions();
  const [showToolTip, setShowToolTip] = useState<any>({ id: -1, active: false });
  const [title, setTitle] = useState<string>('');
  const history = useHistory();
  const helpConfig = useAppSelector((st: RootState) => st.helpConfig.data);
  const { isHelpAvailable } = helpConfig;
  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig);

  useEffect(() => {
    if (isFirstStep) {
      setPageTitle(`${activeSubNav.name} wellbeing`);
    }

    if (!isFirstStep && !isLastStep) {
      const newPageTitle = categoryNames[activeStep - 1];
      setPageTitle(newPageTitle);
    }

    if (activeStep === stepCount - 3) {
      setEditPage(true);
      setTitle('You’ve almost completed your Hapstar!');
    }

    if (activeStep > stepCount - 3) {
      setTitle('');
    }

    setCurrentStep(activeStep);
  }, [activeStep, isLastStep, isFirstStep, stepCount, categoryNames, activeSubNav.name]);

  const handleClick = async () => {
    await nextStep();
  };

  const hintText = categoryQuestion.flatMap((k) => k?.questions)[activeStep - 1]?.hint;

  const companyConfigAdvisorEnabled = companyConfig.data && companyConfig.data.talkToAdvisor;

  const handleCustomStepNavigation = async () => {
    if (
      reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' &&
      reduxStarScores.selectedFocusAreas.length === 0
    ) {
      handleCompletePilarEmail(activeSubNav.id, null);
      history.push(`/stars`);
    } else if (reduxStarScores.createdImprovementPlan) {
      handleCompletePilarEmail(activeSubNav.id, reduxStarScores.createdImprovementPlan.id);
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.createdImprovementPlan.id}&improvePlan=${undefined}`,
      );
    } else if (
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans?.length > 0
    ) {
      handleCompletePilarEmail(
        activeSubNav.id,
        reduxStarScores.deepDiveQuestionWithPlans[0].question.improvePlans[0].id,
      );
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.deepDiveQuestionWithPlans[0].question.improvePlans[0].id}&improvePlan=${reduxStarScores.deepDiveQuestionWithPlans[0].question?.questionText}`,
      );
    }
  };

  const handleAdvisorNavigation = async () => {
    if (companyConfig.data.talkToAdvisor && reduxStarScores.selectedFocusAreas.length > 0) {
      await reduxDispatch(starScoresSliceActions.setAdvisorEnabledWithAreas(true));
    } else if (
      companyConfig.data.talkToAdvisor &&
      reduxStarScores.selectedFocusAreas.length === 0
    ) {
      await reduxDispatch(starScoresSliceActions.setAdvisorEnabledWithAreas(false));
    }
  };

  return (
    <div className={styles.hsStepWizardHeader}>
      <div className="position-relative">
        {!isMobile && <PageTitle title={pageTitle} />}
        {isMobile &&
          ((activeSubNav.id === 1 && activeStep < 22) ||
            (activeSubNav.id !== 1 && activeStep < 25)) && (
            <div className={styles.hsSingleQuestionTitle} style={{ display: 'flex' }}>
              <PageTitle title={pageTitle} />
              {!isFirstStep && activeStep < 25 && (
                <div className={styles.prevButtonContainer}>
                  <button
                    onClick={() =>
                      setShowToolTip((prev: any) => ({ id: activeStep, active: !prev.active }))
                    }
                    onBlur={() =>
                      setShowToolTip((prev: any) => ({ id: activeStep, active: false }))
                    }
                    style={{ border: 'none', background: 'transparent' }}
                  >
                    <Img src={questioMarkIcon} alt="question mark icon" />
                  </button>
                  {showToolTip?.id === activeStep && showToolTip.active && (
                    <p className={styles.tooltipContainer}>{hintText}</p>
                  )}
                </div>
              )}
            </div>
          )}
        {isFirstStep && !isMobile && (
          <DefaultButton variant="primary" type="button" onClick={handleClick}>
            Let’s begin
          </DefaultButton>
        )}
        {!isFirstStep &&
          !isLastStep &&
          !isMobile &&
          ((!isHelpAvailable && activeStep === 7) || (isHelpAvailable && activeStep === 7)) && (
            <div className={styles.hsStepWizardHeaderTitleImage}>
              <AnimatedStarOpacity
                type={pageTitle?.toLowerCase().split(' ').join('-')}
                categoryNames={categoryNames}
              />
            </div>
          )}

        {isMobile && isFirstStep && (
          <div className={styles.beginContainer}>
            <div className={styles.circleIconContainer} onClick={() => history.push('/')}>
              <Img src={backIcon} alt="back icon" />
            </div>
            <div className={styles.button} onClick={handleClick}>
              Let’s begin
            </div>
          </div>
        )}
        {isHelpAvailable && activeStep === 8 && !isMobile && (
          <div className={styles.absoluteTopRightContainer}>
            <div />
            <DefaultButton
              variant="primary"
              type="button"
              onClick={async () => {
                await handleAssessmentData({ assessmentId: 0, questionnaireId: 0 });
                await nextStep();
              }}
            >
              {customButtonName}
            </DefaultButton>
          </div>
        )}

        {!isHelpAvailable &&
          activeStep === 9 &&
          !isMobile &&
          reduxStarScores.isUserSkippedFocusAreas &&
          (reduxStarScores.subNavigationStep === 'hasPlanAndRemovedFocusAreas' ||
            reduxStarScores.subNavigationStep === 'hasPlanAndUpdatedFocusAreas' ||
            reduxStarScores.subNavigationStep === 'hasPlanNoFocusAreas') && (
            <div className={styles.absoluteTopRightContainer}>
              <div />
              <DefaultButton
                variant="primary"
                type="button"
                onClick={async () => {
                  await handleAssessmentData({ assessmentId: 0, questionnaireId: 0 });
                  await nextStep();
                }}
              >
                Take me to my plan
              </DefaultButton>
            </div>
          )}

        {isHelpAvailable &&
          activeStep === 10 &&
          !isMobile &&
          reduxStarScores.isUserSkippedFocusAreas &&
          (reduxStarScores.subNavigationStep === 'hasPlanAndRemovedFocusAreas' ||
            reduxStarScores.subNavigationStep === 'hasPlanAndUpdatedFocusAreas' ||
            reduxStarScores.subNavigationStep === 'hasPlanNoFocusAreas') && (
            <div className={styles.absoluteTopRightContainer}>
              <div />
              <DefaultButton
                variant="primary"
                type="button"
                onClick={async () => {
                  await handleAssessmentData({ assessmentId: 0, questionnaireId: 0 });
                  await nextStep();
                }}
              >
                Take me to my plan
              </DefaultButton>
            </div>
          )}

        {isHelpAvailable &&
          companyConfigAdvisorEnabled &&
          activeStep === 10 &&
          !reduxStarScores.advisorEnabledAndFocusAreasEdited &&
          reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' && (
            <div className={styles.absoluteTopRightContainer}>
              <DefaultButton variant="primary" type="button" onClick={handleCustomStepNavigation}>
                {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' &&
                reduxStarScores.selectedFocusAreas.length === 0
                  ? 'Finish'
                  : 'Take me to my plan'}
              </DefaultButton>
            </div>
          )}

        {!isHelpAvailable &&
          companyConfigAdvisorEnabled &&
          reduxStarScores.isUserSkippedFocusAreas &&
          activeStep === 9 &&
          reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' && (
            <div className={styles.absoluteTopRightContainer}>
              <DefaultButton variant="primary" type="button" onClick={handleCustomStepNavigation}>
                {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' &&
                reduxStarScores.selectedFocusAreas.length === 0
                  ? 'Finish'
                  : 'Take me to my plan'}
              </DefaultButton>
            </div>
          )}

        {!isHelpAvailable &&
          companyConfigAdvisorEnabled &&
          !reduxStarScores.isUserSkippedFocusAreas &&
          !reduxStarScores.advisorEnabledAndFocusAreasEdited &&
          activeStep === 9 &&
          reduxStarScores.subNavigationStep === 'hasPlanAndRemovedFocusAreas' && (
            <div className={styles.absoluteTopRightContainer}>
              <DefaultButton
                variant="primary"
                type="button"
                onClick={() => {
                  if (
                    companyConfigAdvisorEnabled &&
                    reduxStarScores.selectedFocusAreas.length > 0
                  ) {
                    handleAdvisorNavigation();
                  } else {
                    handleCustomStepNavigation();
                  }
                }}
              >
                Take me to my plan
              </DefaultButton>
            </div>
          )}

        {isHelpAvailable &&
          companyConfigAdvisorEnabled &&
          !reduxStarScores.isUserSkippedFocusAreas &&
          !reduxStarScores.advisorEnabledAndFocusAreasEdited &&
          activeStep === 10 &&
          reduxStarScores.subNavigationStep === 'hasPlanAndRemovedFocusAreas' && (
            <div className={styles.absoluteTopRightContainer}>
              <DefaultButton
                variant="primary"
                type="button"
                onClick={() => {
                  if (
                    companyConfigAdvisorEnabled &&
                    reduxStarScores.selectedFocusAreas.length > 0
                  ) {
                    handleAdvisorNavigation();
                  } else {
                    handleCustomStepNavigation();
                  }
                }}
              >
                Take me to my plan
              </DefaultButton>
            </div>
          )}

        {isLastStep && !isMobile && (
          <div className={styles.absoluteTopRightContainer}>
            <div />
            {!companyConfigAdvisorEnabled && (
              <DefaultButton variant="primary" type="button" onClick={handleCustomStepNavigation}>
                {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' &&
                reduxStarScores.selectedFocusAreas.length === 0
                  ? 'Finish'
                  : 'Take me to my plan'}
              </DefaultButton>
            )}

            {companyConfigAdvisorEnabled &&
              !reduxStarScores.advisorEnabledAndFocusAreasEdited &&
              (reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' ||
                reduxStarScores.subNavigationStep === 'hasPlanNoFocusAreas') && (
                <DefaultButton
                  variant="primary"
                  type="button"
                  onClick={() => {
                    if (
                      companyConfigAdvisorEnabled &&
                      reduxStarScores.selectedFocusAreas.length > 0
                    ) {
                      handleAdvisorNavigation();
                    } else {
                      handleCustomStepNavigation();
                    }
                  }}
                >
                  {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' &&
                  reduxStarScores.selectedFocusAreas.length === 0
                    ? 'Finish'
                    : 'Take me to my plan'}
                </DefaultButton>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

interface StepWizardHeaderInterface {
  categoryNames: string[];
  handleAssessmentData: (data: { assessmentId: number; questionnaireId: number }) => void;
  customButtonName: string;
}

export default StepWizardHeader;
