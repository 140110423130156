import { getCompanyLoginMethodsByUrl } from '@api/auth/company';
import { getIpAddress } from '@api/auth/login';
import { createLog } from '@api/log';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import useGoNative from '@hooks/useGoNative';
import { CompanyLoginMethodsInterface } from '@interfaces/index';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import { Skeleton } from '@mui/material';
import { castResponse, LogTypes } from 'custom.d';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styles from './index.module.scss';

const SharingLink: React.FunctionComponent = () => {
  const isAuthenticated = Boolean(localStorage.getItem('token'));
  const isMobileApp = useGoNative();
  const history = useHistory();
  const location = useLocation();
  const { companyURL } = useParams<UrlParamsType>();
  const [companyDetails, setCompanyDetails] = useState<CompanyLoginMethodsInterface | null>(null);

  let organizationLogo: string | undefined;
  let organizationAltText: string | undefined;

  const [loading, setLoading] = useState<boolean>(true);
  const queryParams = new URLSearchParams(location.search);
  const redirectPath = queryParams.get('redirect');

  const navigateUserToRedirectPath = async () => {
    let redirect = redirectPath;

    if (redirectPath && redirectPath.charAt(0) !== '/') {
      redirect = `/${redirectPath}`;
    }

    const ipAddress = await getIpAddress();
    const payload = {
      type: LogTypes.DIRECT_LINK,
      ipAddress,
      os: navigator.userAgentData?.platform || '',
      isNative: isMobileApp,
    };

    await createLog(payload);

    localStorage.removeItem('isDirectLink');
    localStorage.removeItem('redirectPath');
    history.push(redirect || '/');
  };

  const getCompanyData = async () => {
    try {
      const response = await getCompanyLoginMethodsByUrl(companyURL);
      if (response.success) {
        const data = castResponse<CompanyLoginMethodsInterface>(response);
        if (data.companyLoginMethods.length === 1) {
          window.location.replace(data.companyLoginMethods[0].loginUrl);
        } else if (data.companyLoginMethods.length === 0) {
          history.push(`/login/${data.companyUrl}`);
        } else {
          setCompanyDetails(data);
          setLoading(false);
        }
      } else {
        setCompanyDetails(null);
        history.push('/login');
      }
    } catch {
      setCompanyDetails(null);
      history.push('/login');
    }
  };

  useEffect(() => {
    setLoading(true);
    localStorage.setItem('isDirectLink', 'true');
    if (redirectPath) {
      localStorage.setItem('redirectPath', redirectPath);
    }

    if (isAuthenticated) {
      navigateUserToRedirectPath();
    } else {
      getCompanyData();
    }
  }, [companyURL]);

  if (companyDetails !== null) {
    organizationLogo = companyDetails.companyLogo
      ? `${process.env.PUBLIC_URL}/images/logos/${companyDetails.companyLogo}`
      : undefined;
    organizationAltText = `${companyDetails.companyName} logo`;
  }

  return (
    <AuthenticationLayout
      title="Sharing Link"
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
    >
      <div className={styles.cardContainer}>
        {loading ? (
          <div className={styles.contentContainer}>
            <Skeleton variant="rounded" height={300} className={styles.loader} />
          </div>
        ) : (
          <Card>
            <div className={styles.contentContainer}>
              <div className={styles.content}>
                <h1>Select Login option</h1>
                {companyDetails &&
                  companyDetails.companyLoginMethods.map((login, index) => (
                    <div className={styles.buttonHolder} key={index}>
                      <DefaultButton
                        variant="primary"
                        type="button"
                        onClick={() => {
                          window.location.replace(login.loginUrl);
                        }}
                      >
                        {login.loginMethodText}
                      </DefaultButton>
                    </div>
                  ))}
              </div>
            </div>
          </Card>
        )}
      </div>
    </AuthenticationLayout>
  );
};

type UrlParamsType = {
  companyURL: string;
};

export default SharingLink;
