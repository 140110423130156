import React, { useState } from 'react';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import Card from '@components/Atoms/Card';
import LinkText from '@components/Atoms/Link';
import useGoNative from '@hooks/useGoNative';
import Img from '@components/Atoms/Img';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { useHistory } from 'react-router';
import checkYourInboxImg from '@assets/images/check-inbox.svg';
import TokenInput from '@components/Organisms/TokenInput';
import { LoginTypes, loginUser } from '@api/auth/login';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const CheckYourInbox: React.FunctionComponent<CheckYourInboxProps> = (props) => {
  const {
    organizationLogo,
    organizationAltText,
    companyURL,
    email,
    hideHapstarLogo,
    platformName,
  } = props;
  const { isMobile } = useWindowDimensions();
  const isMobileApp = useGoNative();
  const history = useHistory();
  const [emailResendCount, setEmailResendCount] = useState(0);
  const hideHapstarLogoEnabled = hideHapstarLogo;

  let type = LoginTypes.LINK;
  if (isMobileApp) {
    type = LoginTypes.TOKEN;
  }

  const handleResendEmail = async () => {
    if (emailResendCount < 3) {
      try {
        const response = await loginUser({
          email: email.toLowerCase(),
          type,
        });

        toast('Email Sent');
      } catch (err: any) {
        toast(err.json.message);
      }
    } else {
      toast('Please try again after few hours');
    }

    setEmailResendCount((preState) => preState + 1);
  };

  const handleOnBack = async () => {
    history.push(
      `/login${companyURL ? `/${companyURL}` : ''}${isMobileApp ? '/?isMobileApp=true' : ''}`,
    );
  };

  return (
    <AuthenticationLayout
      title="CheckYourInbox"
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
      hideHapstarLogoEnabled={hideHapstarLogoEnabled}
    >
      <div className={styles.cardContainer}>
        <Card>
          <div className={styles.checkInboxContentContainer}>
            <div className={styles.checkInboxContent}>
              {organizationLogo && isMobile && !hideHapstarLogoEnabled && (
                <div className={styles.organizationLogoHolder}>
                  <Img alt={organizationAltText || 'Organization logo'} src={organizationLogo} />
                </div>
              )}
              <Img src={checkYourInboxImg} alt="Check you inbox" className={styles.img} />
              {isMobileApp ? (
                <div className={styles.contentContainer}>
                  <h1>Check your email</h1>
                  <p>We have sent an activation email to: {email}</p>
                  <p>Enter the verification code below.</p>
                  <div className={styles.token}>
                    <TokenInput email={email} />
                  </div>
                </div>
              ) : (
                <div className={styles.contentContainer}>
                  <h1>Check your email to confirm your account</h1>
                </div>
              )}
              <p className={styles.resendText}>
                Didn’t receive an email?{' '}
                <LinkText onClick={handleResendEmail} disable={!(emailResendCount <= 3)}>
                  Resend
                </LinkText>
              </p>
              <LinkText onClick={handleOnBack}>Back</LinkText>
            </div>
          </div>
        </Card>
      </div>
    </AuthenticationLayout>
  );
};

CheckYourInbox.displayName = 'CheckYourInbox';

CheckYourInbox.defaultProps = {
  organizationLogo: undefined,
  organizationAltText: undefined,
  companyURL: undefined,
  hideHapstarLogo: false,
  platformName: null,
};

interface CheckYourInboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  email: string;
  organizationLogo?: string | undefined;
  organizationAltText?: string | undefined;
  companyURL?: string;
  hideHapstarLogo?: boolean;
  platformName?: string | null;
}

export default CheckYourInbox;
