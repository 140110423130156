import React from 'react';
import styles from './index.module.scss';

const Card: React.FunctionComponent<CardProps> = (props) => {
  const { children, className, style } = props;

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  className: styles.card,
  style: {},
};

interface CardProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default Card;
