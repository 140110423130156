import {
  LearningDataInterface,
  LearningResponseInterface,
  SingleLearningContentInterface,
} from '@interfaces/index';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getAllLearningByFilterCriteria = async (req: {
  order: string;
  orderBy: string;
  page: number;
  perPage: number;
  questionIds: number[];
  tagIds: number[];
  companyContentOnly?: boolean;
}) => {
  const formattedReq = {
    order: req.order,
    orderBy: req.orderBy,
    page: req.page,
    perPage: req.perPage,
    questionIds: req.questionIds,
    tagIds: req.tagIds,
    companyContentOnly: req.companyContentOnly,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-all-learning-content`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    const error = new Error(`Failed to get learnings`);
    throw error;
  }

  const result: LearningResponseInterface = await response.json();

  return result;
};

export const getLearningContentById = async (req: { contentId: number }) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-learning-content/${req.contentId}`,
    {
      method: 'GET',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    const error = new Error(`Failed to get learning data`);
    throw error;
  }

  const result: SingleLearningContentInterface = await response.json();

  return result;
};

export const getAllLearningByTagId = async (req: { tagId: number }) => {
  const formattedReq = {
    tagId: req.tagId,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-all-learning-content-by-tag`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    const error = new Error(`Failed to get learnings`);
    throw error;
  }

  const result: LearningDataInterface[] = await response.json();

  return result;
};

export const getLearningsByTitle = async (title?: string) => {
  const formattedReq = {
    title,
  };
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-learnings-by-title`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    const error = new Error(`Failed to get learnings`);
    throw error;
  }

  const result = await response.json();

  return result;
};

export const refetchLearningsToDB = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/refetch-learning-articles`,
    {
      method: 'GET',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    const error = new Error(errorMessage || `Failed to fetch learnings from wordpress`);
    throw error;
  }

  const result = await response.json();

  return result;
};

export const getLearningQuestions = async (learningId: number, shortQuestions: boolean) => {
  const formattedReq = {
    learningId,
    shortQuestions,
  };
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-learning-questions`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    const error = new Error(`Failed to get learning questions`);
    throw error;
  }

  const result = await response.json();

  return result;
};

export const getCompaniesWithLearnings = async (contentId: number) => {
  const formattedReq = {
    contentId,
  };
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-all-companies-with-content`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    const error = new Error(errorMessage || `Failed to get companies`);
    throw error;
  }

  const result = await response.json();

  return result;
};

export const addLearningContentProperties = async (
  learningId: number,
  mainDriveIds: number[],
  subDriveIds: number[],
  tagIds: number[],
  companyIds: number[],
) => {
  const formattedReq = {
    learningId,
    mainDriveIds,
    subDriveIds,
    tagIds,
    companyIds,
  };
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/add-learning-content-properties`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    const error = new Error(errorMessage || `Failed to save learning content properties`);
    throw error;
  }

  const result = await response.json();

  return result;
};
