import Seo from '@components/SEO';
import useGoNative from '@hooks/useGoNative';
import DefaultFooter from '@layouts/LayoutComponents/Footer';
import classNames from 'classnames';
import { TwitterCardType } from 'custom.d';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import styles from './index.module.scss';

const AuthenticationLayout: React.FunctionComponent<AuthenticationLayoutProps> = (props) => {
  const {
    children,
    title,
    description,
    name,
    type,
    organizationLogo,
    organizationAltText,
    hideHapstarLogoEnabled,
  } = props;
  const isMobileApp = useGoNative();

  return (
    <div className={classNames([styles.hsAuthLayout, isMobileApp && styles.hsFooterMobile])}>
      <Seo title={title} description={description} name={name} type={type} />
      <ToastContainer hideProgressBar draggable={false} theme="dark" />
      <Header
        organizationLogo={organizationLogo}
        organizationAltText={organizationAltText}
        hideHapstarLogoEnabled={hideHapstarLogoEnabled}
      />
      <div className={styles.hsAuthLayoutContent}>{children}</div>
      <DefaultFooter />
    </div>
  );
};

AuthenticationLayout.defaultProps = {
  description: '',
  name: '',
  type: 'summary',
  organizationLogo: undefined,
  organizationAltText: undefined,
  hideHapstarLogoEnabled: false,
};

interface AuthenticationLayoutProps {
  children: React.ReactNode | undefined;
  title: string;
  description?: string;
  name?: string;
  type?: TwitterCardType;
  organizationAltText?: string;
  organizationLogo?: string | undefined;
  hideHapstarLogoEnabled?: boolean;
}

export default AuthenticationLayout;
