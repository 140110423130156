import React from 'react';
import logo from '../../../assets/images/logo.svg';
import styles from './index.module.scss';

const DefaultFooter: React.FunctionComponent = () => (
  <footer className={`${styles.hsFooter}`}>
    <div className={styles.innerRootDesktop}>
      <div>
        <div className={styles.navRoot}>
          <p>&copy; Hapstar {new Date().getFullYear()}</p>
          <a href="https://hapstar.app/terms/" target="_blank" rel="noopener noreferrer">
            Terms &amp; Conditions
          </a>
          <a href="https://hapstar.app/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy &amp; Cookie Policy
          </a>
        </div>
      </div>

      <div>
        <img src={logo} alt="logo" />
      </div>
    </div>

    <div className={styles.innerRootMobile}>
      <div className={styles.sections}>
        <div className={styles.top}>
          <a href="https://hapstar.app/terms/" target="_blank" rel="noopener noreferrer">
            Terms &amp; Conditions
          </a>
          <a href="https://hapstar.app/privacy/" target="_blank" rel="noopener noreferrer">
            Privacy &amp; Cookie Policy
          </a>
        </div>
        <div className={styles.bottom}>
          <p>&copy; Hapstar {new Date().getFullYear()}</p>
          <div>
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default DefaultFooter;
