import React from 'react';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import Card from '@components/Atoms/Card';
import LinkText from '@components/Atoms/Link';
import useGoNative from '@hooks/useGoNative';
import Img from '@components/Atoms/Img';
import useWindowDimensions from '@hooks/useWindowDimensions';
import LoginForm from '@components/Organisms/LoginForm';
import styles from './index.module.scss';

const Login: React.FunctionComponent<LoginProps> = (props) => {
  const {
    content,
    organizationLogo,
    organizationAltText,
    organizationId,
    companyURL,
    hideHapstarLogo,
    platformName,
  } = props;
  const { isMobile } = useWindowDimensions();
  const isMobileApp = useGoNative();
  const hideHapstarLogoEnabled = hideHapstarLogo;

  return (
    <AuthenticationLayout
      title="Login"
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
      hideHapstarLogoEnabled={hideHapstarLogoEnabled}
    >
      <div className={styles.cardContainer}>
        {isMobileApp && (
          <div className={styles.subHeading}>
            <h1>“Unlocking the power of happiness”</h1>
          </div>
        )}
        <Card>
          <div className={styles.loginContentContainer}>
            <div className={styles.loginContent}>
              {organizationLogo && isMobile && !hideHapstarLogo && (
                <div className={styles.organizationLogoHolder}>
                  <Img
                    alt={organizationAltText || 'Organization logo'}
                    src={organizationLogo}
                    className={styles.img}
                  />
                </div>
              )}
              <h1>{platformName ? `Login to ${platformName}` : 'Login to Hapstar'}</h1>
              <div className={styles.loginDetails}>
                {content && content}
                <LoginForm organizationId={organizationId} companyURL={companyURL} />
                <div className={styles.gotAccountHolder}>
                  <p>
                    Don’t have an account? <br />
                    <LinkText
                      path={`/signup${companyURL ? `/${companyURL}` : ''}${
                        isMobileApp ? '?isMobileApp=true' : ''
                      }`}
                    >
                      Sign up
                    </LinkText>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </AuthenticationLayout>
  );
};

Login.displayName = 'Login';

Login.defaultProps = {
  content: undefined,
  organizationLogo: undefined,
  organizationAltText: undefined,
  organizationId: 0,
  companyURL: undefined,
  hideHapstarLogo: false,
  platformName: null,
};

interface LoginProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  content?: JSX.Element | string | undefined;
  organizationLogo?: string | undefined;
  organizationAltText?: string | undefined;
  organizationId?: number;
  companyURL?: string;
  hideHapstarLogo?: boolean;
  platformName?: string | null;
}

export default Login;
